import { datadogLogs } from '@datadog/browser-logs';

export interface ILogger {
  debug(message: string): void;
  info(message: string): void;
  warn(message: string): void;
  error(message: string): void;
  addContext(key: string, value: any): void;
  setContext(context: any): void;
}

const logger = datadogLogs.logger;

const debug = (message: string) => {
  logger.debug(message);
};

const info = (message: string) => {
  logger.info(message);
};

const warn = (message: string) => {
  logger.warn(message);
};

const error = (message: string) => {
  logger.error(message);
};

const addContext = (key: string, value: any) => {
  logger.addContext(key, value);
};

const setContext = (context: any) => {
  logger.setContext(context);
};

export const getLogger = (name: string): ILogger => ({
  debug(message: string) {
    debug(`${name}: ${message}`);
  },
  info(message: string) {
    info(`${name}: ${message}`);
  },
  warn(message: string) {
    warn(`${name}: ${message}`);
  },
  error(message: string) {
    error(`${name}: ${message}`);
  },
  addContext(key: string, value: any) {
    addContext(key, value);
  },
  setContext(context: any) {
    // using format of { data: { jsonPayload: {} }} as standard for custom data
    setContext({ data: { jsonPayload: context } });
  },
});

const Logger = { debug, error, info, warn } as ILogger;
export default Logger;

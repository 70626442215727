import { StatusType } from '@datadog/browser-logs';
import { getQueryParams } from '@hc-claims/utilities';

const logLevels: Record<string, StatusType> = {
  production: StatusType.info,
  staging: StatusType.debug,
  development: StatusType.debug,
};

export const getLevelForEnv = (env: Environment): StatusType => {
  const { loglevel } = getQueryParams('loglevel');
  if (loglevel) {
    if (Object.values(StatusType).find((i) => i === loglevel)) {
      return loglevel as StatusType;
    }
    console.warn(`Unknown log level ${loglevel}, defaulting to debug`);
    return 'debug';
  }
  return logLevels[env] || 'debug';
};

import { useEffect } from 'react';
import { EventType } from '@hc-claims/utilities';
import { createEvent } from '@hc-claims/cfar-api';
import { usePartnerContext } from '@hc-claims/ui';
import { selectSessionId, useStore } from '../../../../store';

export const useCreateAnalyticsEvent = (
  eventType: EventType,
  contractId?: string,
) => {
  const { partnerToken } = usePartnerContext();
  const sessionId = useStore(selectSessionId);

  useEffect(() => {
    createEvent(partnerToken, sessionId, eventType, contractId);
  }, [contractId, partnerToken, sessionId, eventType]);
};

import { Outlet, RouteObject, createBrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { Loading } from '@hc-claims/ui';
import {
  AuthenticationErrorPage,
  AuthenticationPage,
  ProtectedRoute,
  VerificationPage,
} from '../common/Authentication';
import { ServiceDisruptionPage } from '../common/ServiceDisruptionPage';
import { Layout } from '../common/Layout';
import type {} from '@remix-run/router';
import { AIR_CFAR } from '../products/air/cfar';
import { AIR_DISRUPTION } from '../products/air/disruption';
import { HOTEL_CFAR } from '../products/hotels/cfar';
import { HOTEL_LFAR } from '../products/hotels/lfar';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { ShutOffAutomaticExercisePage } from '../common/ShutOffAutomaticExercisePage';
import {
  AUTHENTICATION_ERROR_PAGE_ROUTE,
  AUTHENTICATION_PAGE_ROUTE,
  SERVICE_DISRUPTION_ROUTE,
  SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE,
  VERIFICATION_ROUTE,
} from './constants';

export const routes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        element: (
          <Suspense fallback={<Loading isOpen />}>
            <Outlet />
          </Suspense>
        ),
        errorElement: <ErrorBoundary />,
        children: [
          // Commmon Unprotected Routes
          {
            path: AUTHENTICATION_PAGE_ROUTE,
            element: <AuthenticationPage />,
          },
          {
            path: AUTHENTICATION_ERROR_PAGE_ROUTE,
            element: <AuthenticationErrorPage />,
          },
          {
            path: VERIFICATION_ROUTE,
            element: <VerificationPage />,
          },
          {
            path: SERVICE_DISRUPTION_ROUTE,
            element: <ServiceDisruptionPage />,
          },
          {
            path: SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE,
            element: <ShutOffAutomaticExercisePage />,
          },
          {
            element: <ProtectedRoute />,
            children: [
              // Product Routes
              AIR_DISRUPTION,
              HOTEL_CFAR,
              HOTEL_LFAR,
              AIR_CFAR,
            ],
          },

          // Unmatched Route
          {
            path: '*',
            element: <>Not Found</>,
          },
        ],
      },
    ],
  },
];

export const router = createBrowserRouter(routes);

import { PartnerId, partnerIdToPartnerSnakeKey } from '@hc-claims/utilities';
import memoizeOne from 'memoize-one';
import { DEFAULT_NAMESPACE } from './constants';

const DEFAULT_NAMESPACES = [DEFAULT_NAMESPACE];

function partnerNamespaces(id: PartnerId): string[] {
  if (id === PartnerId.HOPPER) {
    return DEFAULT_NAMESPACES;
  }
  return [partnerIdToPartnerSnakeKey[id], DEFAULT_NAMESPACE];
}

export const nameSpacesForPartner = memoizeOne(partnerNamespaces);

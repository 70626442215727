import type { Browser, OperatingSystem } from '@hc/partner-air-api-client';
import { UAParser } from 'ua-parser-js';
import { getLogger } from '../logger';

const deviceIdStorageKey = '__HC_DEVICE_ID__';

const logger = getLogger('DeviceInfo');

let parser = new UAParser();

function setDeviceId(newDeviceId: string) {
  try {
    window.localStorage.setItem(deviceIdStorageKey, newDeviceId);
  } catch (e) {
    logger.warn(`Failed to set deviceId in localStorage: ${e}`);
  }
}

// copy over from hotel to replace window.crypto.randomUUID as it seems like it does not have good support coverage. (got error on safari 14.1.1)
export function generateUniqueId(): string {
  const radix = 32;
  let d = new Date().getTime();
  return Array.from(Array(32))
    .map(() => {
      let r = Math.random() * radix;
      // eslint-disable-next-line no-bitwise
      r = (d + r) % radix | 0;
      d = Math.floor(d / radix);
      return r.toString(radix);
    })
    .join('');
}

function getDeviceId(): string {
  let deviceId;
  try {
    deviceId = window.localStorage.getItem(deviceIdStorageKey);
  } catch (e) {
    logger?.warn(`Failed to get deviceId from localStorage: ${e}`);
  }

  if (!deviceId) {
    deviceId = generateUniqueId();
    setDeviceId(deviceId);
  }

  return deviceId;
}

export enum UserAgentDeviceTypeEnum {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}

// key value from https://github.com/faisalman/ua-parser-js#methods
const uaOSNameToOSTypeMapping: Record<string, string> = {
  'Android[-x86]': 'android',
  'Chromium OS': 'chrome_os',
  iOS: 'i_os',
  Linux: 'linux',
  'Mac OS': 'mac_os',
  'Windows [Phone/Mobile]': 'windows',
  Windows: 'windows',
  Unknown: 'other_os',
};

// key value from https://github.com/faisalman/ua-parser-js#methods
const uaBrowserNameTypeMapping: Record<string, string> = {
  Chrome: 'chrome',
  Edge: 'edge',
  'Opera Coast': 'firefox',
  'Opera [Mini/Mobi/Tablet]': 'opera',
  Safari: 'safari',
  Other: 'other_browser',
};

export const getPlatformOSData = (): OperatingSystem => {
  const osDataFromParser = parser.getOS();
  const operatingSystem: OperatingSystem = {
    name: osDataFromParser.name,
    version: osDataFromParser.version,
    type: (uaOSNameToOSTypeMapping[osDataFromParser.name] ||
      uaOSNameToOSTypeMapping.Unknown) as any,
  };

  return operatingSystem;
};

export const getPlatformBrowserData = (): Browser => {
  const browserDataFromParser = parser.getBrowser();
  const browser: Browser = {
    version: browserDataFromParser.version,
    type: (uaBrowserNameTypeMapping[browserDataFromParser.name] ||
      uaBrowserNameTypeMapping.Other) as any,
  };

  return browser;
};

export function getDeviceData() {
  return {
    id: getDeviceId(),
    platform: {
      operating_system: getPlatformOSData(),
      browser: getPlatformBrowserData(),
      type: 'web',
    },
    type: parser.getDevice()?.type || UserAgentDeviceTypeEnum.Desktop,
  };
}

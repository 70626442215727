import { useNavigate } from 'react-router-dom';
import { IconButton, IconButtonProps } from 'theme-ui';
import BackIcon from '../../../assets/back.svg?react';

export const BackButton = ({ color, ...rest }: IconButtonProps) => {
  const navigate = useNavigate();

  return (
    <IconButton
      data-testid="back-button"
      aria-label="Go Back"
      onClick={() => navigate(-1)}
      color={color ?? 'text'}
      {...rest}
    >
      <BackIcon height={24} width={24} />
    </IconButton>
  );
};

import { Box, Card, Heading, Paragraph, Field, Text, Badge } from 'theme-ui';
import { Loading } from '@hc-claims/ui';
import { alpha } from '@theme-ui/color';
import { Suspense } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { usePartnerTranslations } from '../../hooks';
import { VerificationCodeFormData } from './types';

export const VerificationCard = ({ name }: { name: string }) => {
  const { t } = usePartnerTranslations();
  const {
    formState: { errors },
    ...formRest
  } = useFormContext<VerificationCodeFormData>();

  return (
    <Card sx={{ variant: 'cards.verificationPage.verificationCard' }}>
      <Heading
        as="h2"
        mb={4}
        data-testid="verification-code-title"
        variant="text.heading.verifyYourEmail"
      >
        {t?.('verify_your_email')}
      </Heading>
      <Paragraph data-testid="verification-code-description">
        {t?.('please_enter_verification')}
      </Paragraph>
      <Badge
        mt={3}
        mb={4}
        px={4}
        py={2}
        backgroundColor={alpha('primary', 0.1)}
        color="primary"
        sx={{
          fontSize: '14px',
          borderRadius: '50px',
          whiteSpace: 'pre-wrap',
          maxWidth: '420px',
          variant: 'styles.div.verificationPage.spamBadge',
        }}
        data-testid="check-spam-badge"
      >
        {t?.('check_spam')}
      </Badge>
      <Suspense fallback={<Loading isOpen />}>
        <Box mb={3}>
          <Controller
            name="verificationCode"
            render={({ field }) => (
              <Field
                title={name}
                label={t('verification_code') || undefined}
                data-testid="verification-code-input"
                {...field}
              />
            )}
            rules={{
              required: true,
            }}
            {...formRest}
          />
          {errors.verificationCode && (
            <Box
              data-testid="verification-code-error-message"
              sx={{ variant: 'styles.div.fieldErrorMessage' }}
            >
              <Text role="alert" variant="error">
                {t?.('error_required')}
              </Text>
            </Box>
          )}
          <Box mt={4}>
            <Text data-testid="verification-code-expiration" variant="italic">
              {t?.('verification_code_expiration')}
            </Text>
          </Box>
        </Box>
      </Suspense>
    </Card>
  );
};

export const sessionIdStorageKey = '__HC_SESSION_ID__';
export const CLAIMS_APP_STORAGE_KEY = 'hc-claims-app';

export enum EventType {
  CFAR_ENTER_AUTHENTICATE_PAGE = 'cfar_enter_authenticate_page',
  CFAR_ENTER_VERIFICATION_PAGE = 'cfar_enter_verification_page',
  CFAR_ENTER_CONTRACT_PAGE = 'cfar_enter_contract_page',
  CFAR_ENTER_REGISTER_PAGE = 'cfar_enter_register_page',
  CFAR_ENTER_TRANSFER_METHOD_PAGE = 'cfar_enter_transfer_method_page',
  CFAR_ENTER_COMPLETE_PAGE = 'cfar_enter_complete_page',
  CFAR_ENTER_PAYMENT_ERROR_PAGE = 'cfar_enter_payment_error_page',
}

export enum PartnerId {
  AGODA = '5f983288-e4d6-459d-ad23-078c03ec32ef',
  DESPEGAR = '9d6010ca-feb5-4103-901f-8fa59660de6c',
  HOPPER = '23459807-1a9a-4227-a7aa-226e3c5552d1',
  PRICELINE = '220de1a5-2871-4657-a437-10b10c7db9ff',
  TRAVIX_BUDGET_AIR = 'c3a31785-7279-454a-9367-ab4a469079a8',
  TRAVIX_CHEAPTICKETS = 'c9b3bcd1-1be7-41b6-99c5-8c4f60a57c9b',
  TRAVIX_FLUGLADEN = 'dade964b-a546-48e0-98d6-fc3c545cce08',
  TRAVIX_VAYAMA = 'e5cae61b-5b18-4ad3-a1eb-283e402d2e63',
  TRAVIX_VLIEGWINKEL = '547e2914-f0c6-402f-a553-6183d94e53ba',
}

export const partnerIdToPartnerSnakeKey: Record<PartnerId, string> = {
  [PartnerId.AGODA]: 'agoda',
  [PartnerId.DESPEGAR]: 'despegar',
  [PartnerId.HOPPER]: 'hopper',
  [PartnerId.PRICELINE]: 'priceline',
  [PartnerId.TRAVIX_BUDGET_AIR]: 'travix_budget_air',
  [PartnerId.TRAVIX_CHEAPTICKETS]: 'travix_cheaptickets',
  [PartnerId.TRAVIX_FLUGLADEN]: 'travix_flugladen',
  [PartnerId.TRAVIX_VAYAMA]: 'travix_vayama',
  [PartnerId.TRAVIX_VLIEGWINKEL]: 'travix_vliegwinkel',
};

export const completeUrl: Record<PartnerId, string | undefined> = {
  [PartnerId.AGODA]: 'https://www.agoda.com/',
  [PartnerId.DESPEGAR]: 'https://www.despegar.com.co',
  [PartnerId.HOPPER]: undefined,
  [PartnerId.PRICELINE]: undefined,
  [PartnerId.TRAVIX_BUDGET_AIR]: 'https://www.budgetair.com',
  [PartnerId.TRAVIX_CHEAPTICKETS]: undefined, // undefined bc cheaptickets is listening for onClose event when user clicks Done on CompletePage
  [PartnerId.TRAVIX_FLUGLADEN]: 'https://www.flugladen.de',
  [PartnerId.TRAVIX_VAYAMA]: 'https://www.vayama.ie',
  [PartnerId.TRAVIX_VLIEGWINKEL]: 'https://www.vliegwinkel.nl',
};

export const multipleDomains: Record<PartnerId, boolean> = {
  [PartnerId.AGODA]: false,
  [PartnerId.DESPEGAR]: false,
  [PartnerId.HOPPER]: false,
  [PartnerId.PRICELINE]: false,
  [PartnerId.TRAVIX_BUDGET_AIR]: false,
  [PartnerId.TRAVIX_CHEAPTICKETS]: true,
  [PartnerId.TRAVIX_FLUGLADEN]: true,
  [PartnerId.TRAVIX_VAYAMA]: false,
  [PartnerId.TRAVIX_VLIEGWINKEL]: false,
};

export const FAQsUrl: Record<PartnerId, string> = {
  [PartnerId.AGODA]:
    'https://hkg.agoda.com/info/contact.html?refresh=true#flights-cfar',
  [PartnerId.DESPEGAR]: 'https://hopper-cloud-despegar.kustomer.help/',
  [PartnerId.HOPPER]: '',
  [PartnerId.PRICELINE]: 'https://hopper-cloud-priceline.kustomer.help/',
  [PartnerId.TRAVIX_BUDGET_AIR]:
    'https://hopper-cloud-budgetair.kustomer.help/{{lang}}',
  [PartnerId.TRAVIX_CHEAPTICKETS]:
    'https://hopper-cloud-cheaptickets.kustomer.help/{{lang}}',
  [PartnerId.TRAVIX_FLUGLADEN]:
    'https://hopper-cloud-flugladen.kustomer.help/{{lang}}',
  [PartnerId.TRAVIX_VAYAMA]:
    'https://hopper-cloud-vayama.kustomer.help/{{lang}}',
  [PartnerId.TRAVIX_VLIEGWINKEL]:
    'https://hopper-cloud-vliegwinkel.kustomer.help/{{lang}}',
};

export const ContactUsUrl: Record<PartnerId, string> = {
  [PartnerId.AGODA]:
    'https://hopper-cloud-agoda.kustomer.help/contact/contact-us-HyOIjXO9h',
  [PartnerId.DESPEGAR]:
    'https://hopper-cloud-despegar.kustomer.help/es_es/contact/contact-us-B1ec3HJ5a',
  [PartnerId.HOPPER]: '',
  [PartnerId.PRICELINE]:
    'https://hopper-cloud-priceline.kustomer.help/contact/contact-us-SyQdUWuop',
  [PartnerId.TRAVIX_BUDGET_AIR]:
    'https://hopper-cloud-budgetair.kustomer.help/{{lang}}contact/contact-us-cfar-live-rkvl6Bkcp',
  [PartnerId.TRAVIX_CHEAPTICKETS]:
    'https://hopper-cloud-cheaptickets.kustomer.help/{{lang}}contact/contact-us-Hyr9RSkqp',
  [PartnerId.TRAVIX_FLUGLADEN]:
    'https://hopper-cloud-flugladen.kustomer.help/{{lang}}contact/contact-us-r1eMhBy5a',
  [PartnerId.TRAVIX_VAYAMA]:
    'https://hopper-cloud-vayama.kustomer.help/{{lang}}contact/contact-us-rylxsrk96',
  [PartnerId.TRAVIX_VLIEGWINKEL]:
    'https://hopper-cloud-vliegwinkel.kustomer.help/{{lang}}contact/contact-us-B19phSJ5a',
};

export const HWErrorMapping: Record<
  string,
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'dateOfBirth'
  | 'addressLine1'
  | 'addressLine2'
  | 'city'
  | 'country'
  | 'stateProvince'
  | 'zipPostalCode'
> = {
  invalid_date_of_birth: 'dateOfBirth',
  invalid_state_province: 'stateProvince',
  invalid_postal_code: 'zipPostalCode',
};

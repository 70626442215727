import { PartnerContext } from '@hc-claims/ui';
import { PropsWithChildren } from 'react';
import ThemeProvider from '@hc-claims/theming';
import { useStore } from '../../store';

export const PartnerContextProvider = ({ children }: PropsWithChildren) => {
  const currentPartner = useStore(({ partner }) => partner);
  return (
    <PartnerContext.Provider value={currentPartner}>
      <ThemeProvider partnerId={currentPartner.partnerId}>
        {children}
      </ThemeProvider>
    </PartnerContext.Provider>
  );
};

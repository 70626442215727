import {
  CancelForAnyReasonCFARApi,
  Configuration,
} from '@hc/external-user-api-client';
import { PartnerId } from '@hc-claims/utilities';
import { EXTERNAL_USER_API_URL } from '../config';

export const getApi = (
  partnerId: PartnerId,
  accessToken: string,
  extraConfigs?: Partial<Omit<Configuration, 'accessToken'>>,
): CancelForAnyReasonCFARApi => {
  const config = new Configuration({
    basePath: EXTERNAL_USER_API_URL,
    accessToken,
    headers: {
      'HC-Partner-ID': partnerId,
    },
    ...extraConfigs,
  });

  return new CancelForAnyReasonCFARApi(config);
};

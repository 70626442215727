import { HWTransferMethodType } from '@hc-claims/hyperwallet';
import type { RootState } from '../types';
import { selectTransferMethod } from './selectTransferMethod';

export const transferMethodTypeToI18nKey: Record<string, string> = {
  [HWTransferMethodType.BANK_ACCOUNT]: 'bank_account',
  [HWTransferMethodType.BANK_CARD]: 'bank_card',
  [HWTransferMethodType.PAPER_CHECK]: 'paper_check',
  [HWTransferMethodType.PAYPAL_ACCOUNT]: 'paypal_account',
  [HWTransferMethodType.PREPAID_CARD]: 'prepaid_card',
  [HWTransferMethodType.VENMO_ACCOUNT]: 'venmo_account',
  [HWTransferMethodType.WIRE_ACCOUNT]: 'wire_account',
};
export const selectTransferMethodTypeForI18n = (state: RootState) => {
  const transferMethod = selectTransferMethod(state);

  return transferMethod?.type
    ? (transferMethodTypeToI18nKey[transferMethod.type] ?? '')
    : '';
};

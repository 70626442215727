import {
  selectTransferMethodTypeForI18n,
  useStore as useCfarStore,
} from '@hc-claims/cfar-store';
import { FlightCard, Footer, usePartnerContext } from '@hc-claims/ui';
import {
  EventType,
  formatCurrency,
  getCompleteUrl,
} from '@hc-claims/utilities';
import { useMemo } from 'react';
import { Box, Card, Flex, Heading, Text } from 'theme-ui';

import CheckIcon from '../../../../../../assets/check.svg?react';
import MoneyIcon from '../../../../../../assets/money.svg?react';
import { useStore } from '../../../../../store';
import { usePartnerTranslations } from '../../../../../hooks';
import { useContract, useCreateAnalyticsEvent } from '../../hooks';

export const CompletePage = () => {
  const { partnerId } = usePartnerContext();
  const locale = useStore((state) => state.locale);
  const { t } = usePartnerTranslations();

  const { data: contract } = useContract();

  const passengerCount = contract?.purchase_quote?.fare?.passengers?.length;
  const transferMethodTypeKey = useCfarStore(selectTransferMethodTypeForI18n);

  const formattedRefund = useMemo(
    () =>
      formatCurrency(
        contract?.purchase_quote?.total_coverage,
        contract?.purchase_quote?.offer?.currency,
        locale,
      ),
    [contract, locale],
  );

  const completeFlowAction = () => {
    const url = getCompleteUrl(partnerId, locale);

    if (partnerId && url) {
      window.location.href = url;
    } else {
      parent.postMessage('onClose', '*');
    }
  };

  useCreateAnalyticsEvent(EventType.CFAR_ENTER_COMPLETE_PAGE, contract?.id);

  return (
    <Box data-testid="complete-page">
      <Card pt={0}>
        <Box p={[2, 4]} sx={{ textAlign: 'center' }}>
          <Box p={2} sx={{ color: 'success' }}>
            <CheckIcon height={32} width={32} />
          </Box>
          <Heading
            as="h2"
            data-testid="cancel-confirmation-title"
            variant="highlight"
            mb={4}
          >
            {t?.('your_flight_cancelled')}
          </Heading>
          <Text data-testid="cancel-confirmation-text">
            {t?.('confirmation_email_will_be_sent')}
          </Text>
        </Box>
      </Card>
      <FlightCard
        locale={locale}
        slices={contract?.purchase_quote?.fare?.slices}
        passengerCount={passengerCount}
        bookingLocators={contract?.booking_locators}
      />
      <Card data-testid="refund-method">
        <Heading data-testid="refund-method-title" as="h3" mb={2}>
          <Flex sx={{ alignItems: 'center', gap: 8 }}>
            <Box sx={{ color: 'primary', display: 'flex' }}>
              <MoneyIcon height={24} width={24} />
            </Box>
            <Box sx={{ flex: '1 1 auto' }}>{t?.('refund_method')}</Box>
          </Flex>
        </Heading>
        <Text data-testid="refund-method-option" ml={32}>
          {t?.('refund_option', {
            refund: formattedRefund,
            chosen_option: t?.(transferMethodTypeKey),
          })}
        </Text>
      </Card>
      <Footer
        testID="done-button"
        onClick={completeFlowAction}
        text={t?.('done')}
      />
    </Box>
  );
};

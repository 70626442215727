import { Theme } from '@theme-ui/css';

// Simplified validator based on spec https://www.w3.org/TR/CSS22/syndata.html#value-def-identifier
// Does not check for "cannot start with a digit, two hyphens, or a hyphen followed by a digit"
const keyValidator = /^[A-z0-9][\w-]*$/;

export function removeInvalidKeys(theme: Theme) {
  const clonedTheme: Theme = window.structuredClone
    ? window.structuredClone(theme)
    : JSON.parse(JSON.stringify(theme));

  const traverse = (object: Object) => {
    Object.entries(object).forEach(([key, value]) => {
      if (!keyValidator.test(key)) {
        delete object[key as keyof Object];
        return;
      }

      if (!Array.isArray(value) && Object(value) === value) {
        traverse(value);
      }
    });
  };

  traverse(clonedTheme);

  return clonedTheme;
}

import '@fontsource/roboto';
import { merge, Theme } from 'theme-ui';
import { theme as defaultTheme } from './default';

const PRIMARY_COLOR = '#01A900';

export const theme: Theme = merge(defaultTheme, {
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
  },
  colors: {
    primary: PRIMARY_COLOR,
    accent: PRIMARY_COLOR,
    background: 'FFFFFF',
  },
});

import { useLocation } from 'react-router-dom';
import { ApplicationError, ErrorCode, getLogger } from '@hc-claims/utilities';
import { requireProduct } from '../../router/routeForProduct';
import { AuthenticationTargetState } from './types';

const logger = getLogger('useAuthenticationTarget');

export const useAuthenticationTarget = () => {
  const location = useLocation();
  const state = (location.state || {}) as AuthenticationTargetState;
  const { target } = state;
  if (!target) {
    logger.error(`No authentication target route in location state: ${state}`);
    throw new ApplicationError(ErrorCode.DEFAULT);
  }
  const product = requireProduct(target);
  return { ...product, target };
};

import { Button, Card, Heading } from 'theme-ui';
import { usePartnerContext } from '@hc-claims/ui';
import { useCallback, useMemo } from 'react';
import { generateContactUsUrl } from '@hc-claims/utilities';
import AlertIcon from '../../../assets/alert.svg?react';
import { usePartnerTranslations } from '../../hooks';
import { useStore } from '../../store';
export const ShutOffAutomaticExercisePage = () => {
  const { t } = usePartnerTranslations();
  const { partnerId } = usePartnerContext();
  const locale = useStore((state) => state.locale);

  const contactUsUrl = useMemo(
    () => generateContactUsUrl(partnerId, locale),
    [partnerId, locale],
  );

  const openContactUs = useCallback(() => {
    window.open(contactUsUrl, '_blank');
  }, [contactUsUrl]);

  return (
    <div data-testid="service-disruption-page">
      <Card sx={{ paddingTop: 40, textAlign: 'center' }}>
        <AlertIcon data-testid="alert-icon" />
        <Heading as="h2" sx={{ mb: 3, mt: 3 }}>
          {t?.('error_default_title')}
        </Heading>
        <Button sx={{ mt: 20 }} onClick={openContactUs}>
          {t?.('please_contact_us')}
        </Button>
      </Card>
    </div>
  );
};

export const ENVIRONMENT = import.meta.env.MODE as Environment;
export const VERSION = import.meta.env.VITE_VERSION || 'LOCAL_BUILD';

export const HYPERWALLET_WIDGETS_URL =
  import.meta.env.VITE_HYPERWALLET_WIDGETS_URL || '';
export const HYPERWALLET_API_URL =
  import.meta.env.VITE_HYPERWALLET_API_URL || '';
export const HYPERWALLET_TC_URL = import.meta.env.VITE_HYPERWALLET_TC_URL || '';

export const API_URL = import.meta.env.VITE_API_URL || '';

export const USE_MOCK =
  import.meta.env.VITE_USE_MOCK === 'true' && ENVIRONMENT !== 'production';

export const LD_CLIENT_ID = import.meta.env.VITE_LD_CLIENT_ID || '';

import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ReactNode } from 'react';
import { getAuthToken } from '../../store/authSession';
import { AUTHENTICATION_PAGE_ROUTE } from '../../router/constants';
import { requireProduct } from '../../router/routeForProduct';

export const ProtectedRoute = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => {
  const location = useLocation();
  const { productId } = requireProduct(location);
  const authSession = getAuthToken(productId);

  if (!authSession) {
    return (
      <Navigate
        to={AUTHENTICATION_PAGE_ROUTE}
        replace
        state={{
          target: location,
        }}
      />
    );
  }
  return <>{children ? children : <Outlet />}</>;
};

import { Location, matchPath } from 'react-router-dom';
import {
  AIR_CFAR_ROUTE,
  AUTHENTICATION_PAGE_ROUTE,
} from '../../router/constants';
import { AirCfarPage } from '../../products/air/cfar/routes';

const PAGES_WITH_NO_BACK_BUTTON = [
  AUTHENTICATION_PAGE_ROUTE,
  `${AIR_CFAR_ROUTE}/${AirCfarPage.COMPLETE}`,
];

export const hasBackButton = (location: Location) => {
  // First page visited
  if (location.key === 'default') {
    return false;
  }
  for (let i = 0; i < PAGES_WITH_NO_BACK_BUTTON.length; i++) {
    const currentRoute = PAGES_WITH_NO_BACK_BUTTON[i];
    if (matchPath(currentRoute, location.pathname)) {
      return false;
    }
  }
  return true;
};

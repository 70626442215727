import { ApplicationError, ErrorCode, getLogger } from '@hc-claims/utilities';
import { useRouteError } from 'react-router-dom';
import { useEffect } from 'react';
import { ErrorPage } from '../ErrorPage';

const logger = getLogger('ErrorBoundary');

export function ErrorBoundary() {
  const error = useRouteError();
  let errorCode = ErrorCode.DEFAULT;
  if (error instanceof Response && error.status === 401) {
    errorCode = ErrorCode.INVALID_AUTHENTICATION;
  }
  if (error instanceof ApplicationError) {
    errorCode = error.code;
  }

  useEffect(() => {
    logger.error(`Unexpected error: ${error}, mapped to ${errorCode}`);
  }, [error, errorCode]);

  return <ErrorPage errorCode={errorCode} />;
}

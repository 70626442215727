import { ApplicationError, ErrorCode, ProductType } from '@hc-claims/utilities';
import { Location, matchPath } from 'react-router-dom';
import {
  AIR_CFAR_ROOT,
  AIR_CFAR_ROUTE,
  AIR_DISRUPTION_ROOT,
  AIR_DISRUPTION_ROUTE,
  HOTELS_CFAR_ROOT,
  HOTELS_LFAR_ROOT,
  HOTEL_CFAR_ROUTE,
  HOTEL_LFAR_ROUTE,
} from './constants';

function prefixForProduct(type: ProductType) {
  switch (type) {
    case ProductType.AIR_DISRUPTION:
      return AIR_DISRUPTION_ROOT;
    case ProductType.HOTEL_LFAR:
      return HOTELS_LFAR_ROOT;
    case ProductType.HOTEL_CFAR:
      return HOTELS_CFAR_ROOT;
    case ProductType.AIR_CFAR:
    default:
      return AIR_CFAR_ROOT;
  }
}

export function routeForProduct(id: string, type: ProductType) {
  const prefix = prefixForProduct(type);
  return `${prefix}/${id}`;
}

const productByRoute = {
  [`${AIR_CFAR_ROUTE}/*`]: ProductType.AIR_CFAR,
  [`${AIR_DISRUPTION_ROUTE}/*`]: ProductType.AIR_DISRUPTION,
  [`${HOTEL_CFAR_ROUTE}/*`]: ProductType.HOTEL_CFAR,
  [`${HOTEL_LFAR_ROUTE}/*`]: ProductType.HOTEL_LFAR,
};

type ProductRouteKey = keyof typeof productByRoute;

export function productFromRoute(loc: Location) {
  const routes = Object.keys(productByRoute);

  for (let i = 0; i < routes.length; i++) {
    const currentRoute = routes[i] as ProductRouteKey;
    const match = matchPath(currentRoute, loc.pathname);

    if (match) {
      return {
        productType: productByRoute[currentRoute],
        productId: match.params.contractId as string,
      };
    }
  }
  return undefined;
}

export function requireProduct(loc: Location) {
  const product = productFromRoute(loc);
  if (!product) {
    throw new ApplicationError(ErrorCode.CONTRACT_NOT_FOUND);
  }
  return product;
}

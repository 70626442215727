export enum ErrorCode {
  CANCEL_BOOKING_FAILED = 'error_partner_cancel_booking_failed',
  CONTRACT_NOT_FOUND = 'error_contract_not_found',
  CONTRACT_EXERCISE_ALREADY_COMPLETED = 'error_contract_exercise_already_completed',
  CONTRACT_EXERCISE_ALREADY_PROCESSING = 'error_contract_exercise_already_processing',
  CONTRACT_EXERCISE_FAILED = 'error_contract_exercise_failed',
  INVALID_AUTHENTICATION = 'error_invalid_authentication',
  INVALID_OTP_EMAIL_ADDRESS = 'error_invalid_otp_email_address',
  PAYMENT_FAILURE = 'error_payment_failure',
  DEFAULT = 'error_default',
}

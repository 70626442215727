import {
  getQueryParams,
  type Product,
  ProductType,
  isEnumMember,
} from '@hc-claims/utilities';
import { Location } from 'react-router-dom';
import { productFromRoute, routeForProduct } from '../router/routeForProduct';
import { router } from '../router/router';

export const CONTRACT_ID_PARAM = 'contractId';
export const PRODUCT_ID_PARAM = 'productId';
export const PRODUCT_TYPE_PARAM = 'productType';

export function typeForProduct(type: string) {
  return isEnumMember(type, ProductType) ? type : ProductType.AIR_CFAR;
}

function resolveProductFromQueryParams(): Product | undefined {
  const {
    contractId,
    productId,
    productType = ProductType.AIR_CFAR, // default product type to cover old query param where we only have contractId
  } = getQueryParams(CONTRACT_ID_PARAM, PRODUCT_ID_PARAM, PRODUCT_TYPE_PARAM);

  let resolvedId = contractId;
  if (!contractId && productId) {
    resolvedId = productId;
  }

  if (!resolvedId) return undefined;

  let resolvedProductType = typeForProduct(productType);

  return {
    productId: resolvedId,
    productType: resolvedProductType,
  };
}

export function resolveAndNavigateToProduct() {
  const paramsProduct = resolveProductFromQueryParams();
  // Providing product parameters will cause a navigation to that product path
  if (paramsProduct) {
    const { productId, productType } = paramsProduct;
    const productPath = routeForProduct(productId, productType);
    router.navigate(productPath, {
      replace: true,
    });
    return paramsProduct;
  }
  // Since product isn't provided in params, derive product from route
  return productFromRoute(window.location as unknown as Location);
}

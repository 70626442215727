export const formatDatetimeWithTimezone = (
  date?: Date,
  locale: string = 'en-US',
): string => {
  const formattedDate = new Intl.DateTimeFormat(locale, {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
  }).format(date);

  return date ? formattedDate : '';
};

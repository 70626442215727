import { Box, Flex } from 'theme-ui';

export const HeaderLogos = () => (
  <Flex
    sx={{
      variant: 'styles.div.headerLogosContainer',
    }}
  >
    <Box sx={{ variant: 'styles.logos.leftLogo' }} />
    <Box sx={{ variant: 'styles.logos.rightLogo' }} />
  </Flex>
);

export type Product = {
  productId: string;
  productType: ProductType;
};

export enum ProductType {
  AIR_CFAR = 'air_cfar',
  AIR_DISRUPTION = 'air_disruption',
  HOTEL_CFAR = 'hotels_cfar',
  HOTEL_LFAR = 'hotels_lfar',
}

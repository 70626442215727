import { Box } from 'theme-ui';
import { sendLoginCode } from '@hc-claims/cfar-api';
import { generateContactUsUrl, PartnerId } from '@hc-claims/utilities';
import { usePartnerContext } from '@hc-claims/ui';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ErrorTemplatePage } from '../ErrorTemplate';
import { usePartnerTranslations } from '../../hooks';
import {
  AUTHENTICATION_PAGE_ROUTE,
  VERIFICATION_ROUTE,
} from '../../router/constants';
import { selectSessionId, useStore } from '../../store';
import { requireProduct } from '../../router/routeForProduct';
import { AuthenticationTargetState } from './types';

export const AuthenticationErrorPage = () => {
  const { t } = usePartnerTranslations();
  const navigate = useNavigate();
  const { partnerId } = usePartnerContext();
  const location = useLocation();
  const locale = useStore((state) => state.locale);
  const sessionId = useStore(selectSessionId);
  const { state = {} as AuthenticationTargetState } = location;
  const { target } = state;
  const { productId } = requireProduct(target);

  const goBack = useCallback(() => {
    // Travix - Cheaptickets uses "Contact HTS" button here rather than default "Back" button
    if (partnerId === PartnerId.TRAVIX_CHEAPTICKETS) {
      const contactUsUrl = generateContactUsUrl(partnerId, locale);
      window.open(contactUsUrl, '_blank');
    } else {
      navigate(AUTHENTICATION_PAGE_ROUTE, { state, replace: true });
    }
  }, [locale, navigate, partnerId, state]);

  const resendCode = useCallback(() => {
    sendLoginCode(partnerId, sessionId, productId);
    navigate(VERIFICATION_ROUTE, { state, replace: true });
  }, [productId, navigate, partnerId, sessionId, state]);

  return (
    <div data-testid="authentication-error-page">
      <ErrorTemplatePage
        title={t?.('invalid_verification_code')}
        text={t?.('the_verification_code')}
        leftButton={{
          onClick: goBack,
          text: t?.('close'),
        }}
        rightButton={{
          onClick: resendCode,
          text: t?.('resend_code'),
          rightIcon: <Box variant="styles.div.footerButtonContinueIcon" />,
        }}
      />
    </div>
  );
};

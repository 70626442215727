import { Location, matchPath } from 'react-router-dom';
import {
  AirCfarCurrentPage,
  AirCfarPage,
} from '../../products/air/cfar/routes';
import {
  AIR_CFAR_ROUTE,
  AUTHENTICATION_ERROR_PAGE_ROUTE,
  AUTHENTICATION_PAGE_ROUTE,
  ERROR_PAGE_ROUTE,
  VERIFICATION_ROUTE,
} from '../../router/constants';

export const airCfarPath = (page: AirCfarPage) => `${AIR_CFAR_ROUTE}/${page}`;

export const currentPageFromRoute = {
  [airCfarPath(AirCfarPage.SUMMARY)]: AirCfarCurrentPage.SUMMARY,
  [airCfarPath(AirCfarPage.REGISTER)]: AirCfarCurrentPage.REGISTER,
  [airCfarPath(AirCfarPage.PAYMENT_METHODS)]:
    AirCfarCurrentPage.PAYMENT_METHODS,
  [airCfarPath(AirCfarPage.COMPLETE)]: AirCfarCurrentPage.COMPLETE,
  [airCfarPath(AirCfarPage.INACTIVE)]: AirCfarCurrentPage.INACTIVE,

  [AUTHENTICATION_PAGE_ROUTE]: AirCfarCurrentPage.AUTHENTICATION,
  [AUTHENTICATION_ERROR_PAGE_ROUTE]: AirCfarCurrentPage.AUTHENTICATION_ERROR,
  [ERROR_PAGE_ROUTE]: AirCfarCurrentPage.COMPLETE_ERROR,
  [VERIFICATION_ROUTE]: AirCfarCurrentPage.VERIFICATION,
};

export function titleForPage(loc: Location) {
  const pageRoutes = Object.keys(currentPageFromRoute);
  for (let i = 0; i < pageRoutes.length; i++) {
    const currentRoute = pageRoutes[i];
    if (matchPath(currentRoute, loc.pathname)) {
      return currentPageFromRoute[currentRoute];
    }
  }
  return undefined;
}

import { getLang } from '../getLang';

export enum SupportedLanguages {
  'da',
  'de',
  'en',
  'es',
  'es-ES',
  'fr',
  'id',
  'it',
  'ja',
  'ko',
  'ms',
  'nl',
  'pl',
  'pt',
  'pt-PT',
  'ru',
  'sv',
  'th',
  'tr',
  'vi',
  'zh',
  'zh-HK',
  'zh-TW',
}

const getLangAsEnum = (lang: string): SupportedLanguages =>
  SupportedLanguages[lang as keyof typeof SupportedLanguages];

export const getI18nLanguage = (locale: string = 'en'): string => {
  const lang = getLangAsEnum(locale);
  if (lang !== undefined) return SupportedLanguages[lang];

  const localeRoot = getLang(locale);
  const langRoot = getLangAsEnum(localeRoot);
  if (langRoot !== undefined) return SupportedLanguages[langRoot];

  console.warn(`Could not find language for ${locale}, defaulting to 'en'`);
  return 'en';
};

import { EventType, getLogger } from '@hc-claims/utilities';
import { getSessionApi, parseRequestError } from '../utilities';

const logger = getLogger('Events');

export const createEvent = async (
  partnerToken: string,
  sessionId: string,
  eventType: EventType,
  contractId?: string,
) => {
  if (!contractId) {
    logger.error(`Could not create event: ${eventType} for missing contractId`);
    return;
  }

  try {
    const postEvent = {
      event: {
        session_id: sessionId,
        type: eventType,
        occurred_date_time: new Date(),
        contract_id: contractId,
      },
    };

    await getSessionApi(partnerToken).postEvents(postEvent);
    logger.info(`Successfully created event for ${eventType}`);
  } catch (error) {
    const { message } = await parseRequestError(error);
    logger.error(
      `Could not create event: ${eventType} for contractId: ${contractId}, error: ${message}`,
    );
  }
};

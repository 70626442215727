import { useCallback } from 'react';
import { Box, Button, Card, Flex, Heading, IconButton, Text } from 'theme-ui';
import ErrorIcon from '../../../assets/error.svg?react';

interface ErrorTemplatePageProps {
  title: string;
  text: string;
  leftButton?: {
    text: string;
    onClick: () => void;
    rightIcon?: JSX.Element;
  };
  rightButton?: {
    text: string;
    onClick: () => void;
    rightIcon?: JSX.Element;
  };
}

export const ErrorTemplatePage = ({
  title,
  text,
  leftButton,
  rightButton,
}: ErrorTemplatePageProps) => {
  const onLeftButtonClick = useCallback(() => {
    leftButton?.onClick?.();
  }, [leftButton]);

  const onRightButtonClick = useCallback(() => {
    rightButton?.onClick?.();
  }, [rightButton]);

  return (
    <div data-testid="error-template-page">
      <Card
        sx={{
          paddingTop: 40,
          textAlign: 'center',
          variant: 'cards.errorTemplate',
        }}
      >
        <IconButton
          data-testid="error-icon"
          aria-label="Error icon"
          sx={{
            color: 'error',
            height: '60px',
            width: '60px',
            variant: 'styles.div.errorTemplate.errorIcon',
          }}
        >
          <ErrorIcon />
        </IconButton>
        <Heading
          as="h2"
          sx={{ marginBottom: 10, variant: 'text.heading.errorTemplate.title' }}
        >
          {title}
        </Heading>
        <Text sx={{ paddingBottom: 30, variant: 'text.errorTemplate.message' }}>
          {text}
        </Text>
      </Card>

      {(leftButton || rightButton) && (
        <Box
          sx={{
            variant: 'styles.div.errorTemplate',
          }}
        >
          {leftButton && (
            <Button
              sx={{
                background: 'white',
                color: 'primary',
                paddingLeft: ['50px', '100px'],
                paddingRight: ['50px', '100px'],
                width: '100%',
                border: '1px solid',
                borderColor: 'border',
                variant: 'buttons.errorPage.leftButton',
              }}
              data-testid="left-error-button"
              onClick={onLeftButtonClick}
            >
              <Flex
                sx={{
                  variant: 'styles.div.footerButton',
                  justifyContent: 'center',
                }}
              >
                {leftButton?.text}
                {leftButton?.rightIcon}
              </Flex>
            </Button>
          )}
          {rightButton && (
            <Button
              sx={
                leftButton
                  ? {
                      width: '100%',
                      paddingLeft: ['50px', '100px'],
                      paddingRight: ['50px', '100px'],
                      variant: 'buttons.errorPage.rightButton',
                    }
                  : {
                      width: '100%',
                    }
              }
              data-testid="right-error-button"
              onClick={onRightButtonClick}
            >
              <Flex
                sx={{
                  variant: 'styles.div.footerButton',
                  justifyContent: 'center',
                }}
              >
                {rightButton?.text}
                {rightButton?.rightIcon}
              </Flex>
            </Button>
          )}
        </Box>
      )}
    </div>
  );
};

type CreateThemeProps = {
  font?: string;
  primaryColor: string;
  accentColor: string;
  textColor?: string;
  headingColor?: string;
  successColor: string;
  warningColor: string;
  errorColor: string;
  infoColor: string;
};
export const createTheme = ({
  font = 'Roboto, sans-serif',
  primaryColor,
  accentColor,
  textColor = '#000000',
  headingColor = '#000000',
  successColor,
  warningColor,
  errorColor,
  infoColor,
}: CreateThemeProps) => ({
  fonts: {
    body: font,
    heading: font,
  },
  colors: {
    primary: primaryColor,
    accent: accentColor,
    text: textColor,
    heading: headingColor,
    success: successColor,
    warning: warningColor,
    error: errorColor,
    info: infoColor,
  },
  text: {
    default: {
      color: textColor,
    },
    error: {
      color: errorColor,
    },
    heading: {
      color: headingColor,
    },
  },
  forms: {
    label: {
      color: textColor,
    },
  },
  styles: {
    root: {
      legend: {
        color: headingColor,
      },
    },
  },
});

import { useContext } from 'react';
import { PartnerContext } from '../PartnerContext';

export function usePartnerContext() {
  const partner = useContext(PartnerContext);
  if (!partner) {
    throw new Error('no partner context defined');
  }
  return partner;
}

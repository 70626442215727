import { Card, Heading, Text } from 'theme-ui';
import AlertIcon from '../../../assets/alert.svg?react';
import { usePartnerTranslations } from '../../hooks';

export const ServiceDisruptionPage = () => {
  const { t } = usePartnerTranslations();

  return (
    <div data-testid="service-disruption-page">
      <Card sx={{ paddingTop: 40, textAlign: 'center' }}>
        <AlertIcon data-testid="alert-icon" />
        <Heading as="h2" sx={{ mb: 3, mt: 3 }}>
          {t?.('service_disruption_title')}
        </Heading>
        <Text>{t?.('please_try_again_later')}</Text>
      </Card>
    </div>
  );
};

import { Box, Card, Heading } from 'theme-ui';
import { useLocation } from 'react-router-dom';
import { usePartnerTranslations } from '../../hooks';
import { productFromRoute } from '../../router/routeForProduct';
import { BackButton } from '../BackButton';
import { titleForPage } from './titleForPage';
import { hasBackButton } from './hasBackButton';

export const Header = () => {
  const { t } = usePartnerTranslations();
  const location = useLocation();
  const hasBack = hasBackButton(location);
  const currentPage = titleForPage(location);
  const { productType } = productFromRoute(location) || {};

  return (
    <Card data-testid="header" p={4} sx={{ position: 'relative' }}>
      {hasBack && (
        <Box
          data-testid="header-back-button"
          mt={2}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            p: 3,
            pl: 24,
            variant: 'styles.div.headerBackButtonBox',
          }}
        >
          <BackButton color="backButton" />
        </Box>
      )}
      <Heading
        data-testid="header-text"
        as="h2"
        sx={{
          textAlign: 'left',
          ml: !!hasBack ? 6 : 0,
          pl: !!hasBack ? 5 : 4,
          pr: !!hasBack ? 6 : 0,
          variant: 'text.header',
        }}
      >
        {t?.(productType ? `header_title_${productType}` : 'header_title', {
          context: currentPage,
        })}
      </Heading>
    </Card>
  );
};

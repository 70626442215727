import { ApplicationError, ErrorCode } from '@hc-claims/utilities';
import type { State } from '../types';

export const selectAuthToken = (state: State) => {
  const token = state.userAuthSession?.authToken;
  if (!token) {
    // Should never happen since ProtectedRoute already guards against this
    throw new ApplicationError(ErrorCode.INVALID_AUTHENTICATION);
  }
  return token;
};

import '@fontsource/roboto';
import { merge, Theme } from 'theme-ui';
import { createTheme } from '../utilities/createTheme';
import { theme as defaultTheme } from './default';

export const theme: Theme = merge(
  defaultTheme,
  createTheme({
    primaryColor: '#007CC3',
    accentColor: '#FF7F00',
    successColor: '#00B250',
    warningColor: '#E85046',
    errorColor: '#D2373F',
    infoColor: '#00B0FF',
  }),
);

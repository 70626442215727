export const AIR_DISRUPTION_ROOT = '/air/disruption';
export const AIR_CFAR_ROOT = '/air/cfar';
export const HOTELS_LFAR_ROOT = '/hotels/lfar';
export const HOTELS_CFAR_ROOT = '/hotels/cfar';

export const AIR_CFAR_ROUTE = `${AIR_CFAR_ROOT}/:contractId`;
export const AIR_DISRUPTION_ROUTE = `${AIR_DISRUPTION_ROOT}/:contractId`;
export const HOTEL_CFAR_ROUTE = `${HOTELS_CFAR_ROOT}/:contractId`;
export const HOTEL_LFAR_ROUTE = `${HOTELS_LFAR_ROOT}/:contractId`;

export const ERROR_PAGE_ROUTE = '/error';
export const AUTHENTICATION_PAGE_ROUTE = '/authenticate';
export const AUTHENTICATION_ERROR_PAGE_ROUTE = '/authenticate-error';
export const VERIFICATION_ROUTE = '/verification';
export const SERVICE_DISRUPTION_ROUTE = '/service-disruption';
export const SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE = '/error-occured';

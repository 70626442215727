import { UseQueryResult, useQuery } from 'react-query';
import { CfarContract, getApi } from '@hc-claims/cfar-api';
import { usePartnerContext } from '@hc-claims/ui';
import { ApplicationError, ErrorCode } from '@hc-claims/utilities';
import { useParams } from 'react-router-dom';
import { selectAuthToken, selectSessionId, useStore } from '../../../../store';
import { ProductParams } from '../routes/params';

const TEN_MINUTES = 1000 * 60 * 10;

export function useContract(): UseQueryResult<CfarContract> {
  const { contractId } = useParams() as ProductParams;
  if (!contractId) {
    throw new ApplicationError(ErrorCode.CONTRACT_NOT_FOUND);
  }
  const { partnerId } = usePartnerContext();
  const sessionId = useStore(selectSessionId);
  const authToken = useStore(selectAuthToken);

  return useQuery({
    staleTime: TEN_MINUTES,
    queryKey: ['contracts', contractId],
    queryFn: () =>
      getApi(partnerId, authToken).getCfarContractsId({
        hCSessionID: sessionId,
        id: contractId,
      }),
  });
}

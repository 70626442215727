import { UseQueryResult, useQuery } from 'react-query';
import { useEffect } from 'react';
import {
  CfarContract,
  EXTERNAL_USER_API_URL,
  State,
  handleAPIError,
} from '@hc-claims/cfar-api';
import { usePartnerContext } from '@hc-claims/ui';
import { useNavigate } from 'react-router-dom';
import { ProductType } from '@hc-claims/utilities';
import { AirCfarPage } from '../routes/constants';
import { selectAuthToken, selectSessionId, useStore } from '../../../../store';
import { routeForProduct } from '../../../../router/routeForProduct';
import { useContract } from './useContract';

export function useValidateExercise(): UseQueryResult<
  CfarContract | undefined
> {
  const navigate = useNavigate();
  const { partnerId } = usePartnerContext();
  const sessionId = useStore(selectSessionId);
  const authToken = useStore(selectAuthToken);
  const skipPartnerCallback = useStore((state) => state.skipPartnerCallback);

  const { data: contract, isSuccess } = useContract();

  useEffect(() => {
    if (isSuccess && contract.state !== State.Active) {
      navigate(
        `${routeForProduct(contract.id, ProductType.AIR_CFAR)}/${
          AirCfarPage.INACTIVE
        }`,
        { replace: true },
      );
    }
  }, [isSuccess, contract, navigate]);

  const enabled = isSuccess && contract.state === State.Active;

  return useQuery({
    enabled,
    queryFn: async () => {
      if (skipPartnerCallback) {
        return contract;
      }
      try {
        const response = await fetch(
          `${EXTERNAL_USER_API_URL}/cfar_exercises/?validate_only=true`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              'HC-Session-ID': sessionId,
              'HC-Partner-ID': partnerId,
            },
            body: JSON.stringify({
              contract_id: contract?.id,
            }),
          },
        );

        if (response.ok) {
          return contract;
        } else {
          await handleAPIError(response);
        }
      } catch (e) {
        return handleAPIError(e);
      }
    },
  });
}

import type { QueryClientConfig } from 'react-query';
import { ENVIRONMENT, VERSION } from './config';

export const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: false,
      suspense: true,
      useErrorBoundary: true,
    },
    mutations: {
      useErrorBoundary: true,
    },
  },
};

const DD_SITE = 'us5.datadoghq.com';
const DD_SERVICE = 'hts-air-cfar-claims';

export const RUM_OPTIONS = {
  applicationId: '8903a3af-5eae-4e0d-a347-fc0684d2a909',
  clientToken: 'pub2e7c7b760f95ab31b5baadd17b96c06b',
  site: DD_SITE,
  service: DD_SERVICE,
  env: ENVIRONMENT,
  version: VERSION,
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: 'mask',
  allowedTracingOrigins: [],
  beforeSend(event: any) {
    if (
      event.type === 'error' &&
      event.error.stack === 'No stack, consider using an instance of Error'
    ) {
      // Error thrown by some sort of microsoft email scanner
      if (event.error.message.match(/Object Not Found Matching Id/)) {
        return false;
      }
    }

    event.context = {
      ...event.context,
    };
    return true;
  },
};

export const LOGGER_OPTIONS = {
  clientToken: 'pubc1227a869b47ba8b221db32fc8caacfc',
  site: DD_SITE,
  service: DD_SERVICE,
  env: ENVIRONMENT,
  forwardErrorsToLogs: true,
  version: VERSION,
  sampleRate: 100,
};

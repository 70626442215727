import { AuthorizationApi, Configuration } from '@hc/external-user-api-client';
import { PartnerId } from '@hc-claims/utilities';
import { EXTERNAL_USER_API_URL } from '../config';

export const getAuthApi = (
  partnerId: PartnerId,
  sessionId: string,
): AuthorizationApi => {
  const config = new Configuration({
    basePath: EXTERNAL_USER_API_URL,
    headers: {
      'HC-Session-ID': sessionId,
      'HC-Partner-ID': partnerId,
    },
  });
  return new AuthorizationApi(config);
};

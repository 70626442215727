import { getPartnerAuthToken } from '@hc-claims/cfar-api';
import {
  PartnerId,
  getQueryParams,
  isEnumMember,
  parseJWT,
} from '@hc-claims/utilities';
import { Partner } from '@hc-claims/ui';
import { DEFAULT_PARTNER, useStore } from '../store';

export const PARTNER_TOKEN_PARAM = 'partnerToken';
export const PARTNER_ID_PARAM = 'partnerId';

function resolvePartner(): Partner | undefined {
  const { partnerToken, partnerId } = getQueryParams(
    PARTNER_TOKEN_PARAM,
    PARTNER_ID_PARAM,
  );
  let resolvedId = partnerId;
  if (!partnerId && partnerToken) {
    const { partner_id: partnerIdJWT } = parseJWT(partnerToken);
    resolvedId = partnerIdJWT;
  }

  const validPartnerId = isEnumMember(resolvedId, PartnerId)
    ? resolvedId
    : undefined;

  if (!validPartnerId) {
    return undefined;
  }

  let resolvedToken = partnerToken;
  if (!resolvedToken) {
    resolvedToken = getPartnerAuthToken(validPartnerId);
  }

  return {
    partnerId: validPartnerId,
    partnerToken: resolvedToken,
  };
}

export function partnerFromQueryParameters() {
  let partner = resolvePartner();
  const { partner: storedPartner, setPartner } = useStore.getState();

  if (!partner && storedPartner) {
    partner = storedPartner;
  }

  if (!partner) {
    partner = DEFAULT_PARTNER;
  }

  if (partner.partnerId !== storedPartner?.partnerId) {
    setPartner(partner);
  }

  return partner;
}

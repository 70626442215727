export function isJSONString(input: string) {
  try {
    const data = JSON.parse(input);
    if (data && typeof data === 'object') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

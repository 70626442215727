import isString from 'lodash-es/isString';

export function getQueryParams(
  ...parameters: string[]
): Record<string, string> {
  const searchParamsProxy = new Proxy(
    new URLSearchParams(window.location.search),
    {
      get: (searchParams, prop: string): string | null => {
        if (isString(prop)) {
          return searchParams.get(prop);
        }
        return null;
      },
    },
  ) as unknown as Record<string, string>;
  return parameters.reduce((acc, current) => {
    const paramValue = searchParamsProxy?.[current];
    return paramValue ? Object.assign(acc, { [current]: paramValue }) : acc;
  }, {});
}

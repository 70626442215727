import { datadogLogs } from '@datadog/browser-logs';
import { fetchSessionId } from '@hc-claims/cfar-api';
import {
  getQueryParams,
  PartnerId,
  partnerIdToPartnerSnakeKey,
} from '@hc-claims/utilities';
import { ENVIRONMENT, USE_MOCK } from './config';
import { startMockServiceWorker } from './startMockServiceWorker';
import { initializeI18n } from './i18n';
import {
  getLevelForEnv,
  partnerFromQueryParameters,
  resolveAndNavigateToProduct,
  localeFromQueryParameter,
} from './utilities';
import { getSessionId, setSessionId } from './store';
import { setSkipContractValidation } from './utilities/setSkipContractValidation';
import { LOGGER_OPTIONS, RUM_OPTIONS } from './constants';

const loggerHandler =
  ENVIRONMENT === 'production' ? 'http' : ['http' as const, 'console' as const];

export function initializeLogger(partnerId: string, productId?: string) {
  datadogLogs.init(LOGGER_OPTIONS);
  const partnerName =
    partnerIdToPartnerSnakeKey[(partnerId as PartnerId) ?? PartnerId.HOPPER];

  datadogLogs.onReady(() => {
    datadogLogs.logger.setLevel(getLevelForEnv(ENVIRONMENT));
    datadogLogs.logger.setHandler(loggerHandler);
    datadogLogs.setGlobalContext({
      data: {
        jsonPayload: {
          env: ENVIRONMENT,
          partner_name: partnerName,
          partner_id: partnerId,
          product_id: productId,
        },
      },
    });
  });
}

async function initializeWorker() {
  const { forceMock } = getQueryParams('forceMock');
  const applyMockOverride =
    import.meta.env.mode !== 'production' && forceMock === 'true';

  const enableMockServiceWorker = USE_MOCK || applyMockOverride;

  if (enableMockServiceWorker) {
    await startMockServiceWorker();
  }
  return Promise.resolve();
}

export async function startRumSession() {
  const { datadogRum } = await import('@datadog/browser-rum');

  datadogRum.init(RUM_OPTIONS);
  datadogRum.startSessionReplayRecording();
}

async function initializeSession(partnerToken: string) {
  const sessionId = getSessionId();
  if (sessionId) {
    // keep every sessionId(state/logger/sessionStorage) in sync
    setSessionId(sessionId);
  } else {
    const fetchedSessionId = await fetchSessionId(partnerToken);
    if (fetchedSessionId) {
      setSessionId(fetchedSessionId);
    }
  }
}

export async function initialize() {
  // Initialization requiring query parameters
  setSkipContractValidation();
  const partner = partnerFromQueryParameters();
  const locale = localeFromQueryParameter();
  const { partnerId, partnerToken } = partner;

  await Promise.all([
    initializeWorker(),
    initializeI18n(locale),
    initializeSession(partnerToken),
  ]);

  // Clears parameters when they contain a product
  const product = resolveAndNavigateToProduct();

  initializeLogger(partnerId, product?.productId);

  startRumSession();
}

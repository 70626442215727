import { create, StateCreator } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { RootState } from './types';

const CFAR_STORE_STORAGE_KEY = 'hc-cfar-store';

const stateCreator: StateCreator<RootState> = (set) => ({
  setTransferMethod: (transferMethod) => set({ transferMethod }),
  setRefundRecipient: (refundRecipient) => set({ refundRecipient }),
  setRefundAuthToken: (refundAuthToken) => set({ refundAuthToken }),
});

const rehydrateState = (
  persistedState: unknown,
  currentState: RootState,
): RootState => {
  const persisted = persistedState as RootState;
  return {
    ...currentState,
    ...persisted,
  };
};

const PERSIST_OPTIONS = {
  name: CFAR_STORE_STORAGE_KEY,
  storage: createJSONStorage(() => sessionStorage),
  merge: rehydrateState,
};

export const useStore = create<RootState>()(
  devtools(persist(stateCreator, PERSIST_OPTIONS)),
);

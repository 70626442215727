import { SHUT_OFF_AUTOMATIC_EXERCISE, useFlags } from '@hc-claims/experiments';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE } from '../router/constants';

export const useShutOffAutomaticExerciseFlagCheck = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      flags[SHUT_OFF_AUTOMATIC_EXERCISE] &&
      !matchPath(SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE, location.pathname)
    ) {
      navigate(SHUT_OFF_AUTOMATIC_EXERCISE_ROUTE);
    }
  }, [flags, navigate, location]);
};

import { Configuration, SessionsApi } from '@hc/partner-air-api-client';
import { PARTNER_API_URL } from '../config';

export const getSessionApi = (partnerToken: string) => {
  const config = new Configuration({
    basePath: PARTNER_API_URL,
    accessToken: `Bearer ${partnerToken}`,
  });

  return new SessionsApi(config);
};

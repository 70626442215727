import { ErrorCode } from '@hc-claims/utilities';
import { Card, IconButton, Heading, Text, Flex } from 'theme-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { Footer } from '@hc-claims/ui';
import ErrorIcon from '../../../assets/error.svg?react';
import { ContactUs } from '../contact-us';
import { usePartnerTranslations } from '../../hooks';
import { AUTHENTICATION_PAGE_ROUTE } from '../../router/constants';
import { useStore } from '../../store';

const PLEASE_CONTACT_US = 'please_contact_us';

const getSubtitle = (code: string): string | undefined => {
  switch (code) {
    case ErrorCode.CONTRACT_NOT_FOUND:
      return 'error_contract_not_found_subtitle';
    case ErrorCode.INVALID_AUTHENTICATION:
      return 'error_invalid_authentication_subtitle';
    // no subtitle for these
    case ErrorCode.CONTRACT_EXERCISE_ALREADY_COMPLETED:
    case ErrorCode.CONTRACT_EXERCISE_ALREADY_PROCESSING:
      return;
    default:
      return PLEASE_CONTACT_US;
  }
};

const showContactUs = (code: string): boolean => {
  switch (code) {
    case ErrorCode.CONTRACT_NOT_FOUND:
    case ErrorCode.CONTRACT_EXERCISE_ALREADY_COMPLETED:
    case ErrorCode.CONTRACT_EXERCISE_ALREADY_PROCESSING:
    case ErrorCode.INVALID_AUTHENTICATION:
      return false;
    default:
      return true;
  }
};

type Props = {
  errorCode: ErrorCode;
};

export const ErrorPage = ({ errorCode }: Props) => {
  const navigate = useNavigate();
  const setUserAuthSession = useStore((state) => state.setUserAuthSession);

  const location = useLocation();

  const reAuthenticateUser = () => {
    setUserAuthSession(undefined);
    navigate(AUTHENTICATION_PAGE_ROUTE, {
      replace: true,
      state: { target: location },
    });
  };

  const invalidAuthentication = errorCode === ErrorCode.INVALID_AUTHENTICATION;

  const { t } = usePartnerTranslations();
  const subtitle = getSubtitle(errorCode);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
      data-testid="complete-error-page"
    >
      <Card sx={{ paddingTop: 40, textAlign: 'center' }}>
        <IconButton
          data-testid="error-icon"
          aria-label="Error icon"
          sx={{ color: 'error', height: '60px', width: '60px' }}
        >
          <ErrorIcon />
        </IconButton>
        <Heading as="h2" sx={{ marginBottom: 10 }}>
          {t?.(`${errorCode}_title`)}
        </Heading>
        {subtitle && <Text>{t?.(subtitle)}</Text>}
      </Card>

      {/* if we tell user to contact us in subtitle we should show contactUs links */}
      {(showContactUs(errorCode) || subtitle === PLEASE_CONTACT_US) && (
        <ContactUs />
      )}

      {invalidAuthentication && (
        <Footer
          testID="login-again"
          onClick={reAuthenticateUser}
          text={t?.('login_again')}
        />
      )}
    </Flex>
  );
};

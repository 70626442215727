import { FlightCard, StatusBadge } from '@hc-claims/ui';
import { formatDate } from '@hc-claims/utilities';
import { Box, Card, Divider, Flex, Heading } from 'theme-ui';

import CfarIcon from '../../../../../../assets/cfar.svg?react';
import { ContactUs } from '../../../../../common/contact-us';
import { usePartnerTranslations } from '../../../../../hooks';
import { useStore } from '../../../../../store';
import { useContract } from '../../hooks';

export const InactivePage = () => {
  const { t } = usePartnerTranslations();
  const locale = useStore((state) => state.locale);
  const { data: contract } = useContract();

  const statusTranslation = contract?.state ?? 'default_not_active';
  const contractExpiry = formatDate(
    new Date(
      contract?.purchase_quote?.offer?.contract_expiry as Date,
    ).toString(),
    locale,
  );

  return (
    <>
      <Card data-testid={`${statusTranslation}-status-page`}>
        <Flex sx={{ justifyContent: 'space-between' }} mb={4}>
          <Flex sx={{ alignItems: 'center', variant: 'styles.icons' }}>
            <CfarIcon height={35} width={35} />
            <Heading as="h2" variant="highlight">
              <Box
                data-testid={`${statusTranslation}-title`}
                pl={3}
                sx={{ flex: '1 1 auto' }}
              >
                {t?.(`${statusTranslation}_title`)}
              </Box>
            </Heading>
          </Flex>
          <StatusBadge status={contract?.state ?? ''} t={t} />
        </Flex>
        <Box data-testid={`${statusTranslation}-text`} mb={5}>
          {t?.(`${statusTranslation}_text`, {
            date: contractExpiry,
          })}
        </Box>
        <Divider />
        {contract && (
          <FlightCard
            mb={3}
            useCard={false}
            slices={contract.purchase_quote.fare.slices}
            passengerCount={contract.purchase_quote.fare.passengers.length}
            bookingLocators={contract.booking_locators}
          />
        )}
      </Card>
      <ContactUs />
    </>
  );
};

import { Box, Spinner } from 'theme-ui';
import styles from './Loading.module.scss';

export type LoadingProps = {
  isOpen: boolean;
  testId?: string;
  loadingText?: string | null;
};

export const Loading = ({
  testId = 'loading',
  loadingText,
  isOpen,
}: LoadingProps) =>
  isOpen ? (
    <Box data-testid={testId} className={styles.container}>
      <Spinner className={styles.spinner} title="Loading" />
      {loadingText && <div className={styles.text}>{loadingText}</div>}
    </Box>
  ) : null;

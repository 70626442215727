export const formatCurrency = (
  total?: string,
  currency?: string,
  locale = 'en',
) => {
  return total
    ? new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
        parseFloat(total),
      )
    : '-';
};

export enum AirCfarApiErrorCode {
  CANCEL_BOOKING_FAILED = 'cancel_booking_failed',
  CANCEL_BOOKING_FAILURE = 'cancel_booking_failure',
  CONTRACT_NOT_FOUND = 'contract_not_found',
  EXERCISE_ALREADY_COMPLETED = 'exercise_already_completed',
  EXERCISE_ALREADY_PROCESSING = 'exercise_already_processing',
  EXERCISE_FAILED = 'exercise_failed',
  EXERCISE_NOT_FOUND = 'exercise_not_found',
  PAYMENT_FAILURE = 'payment_failure',

  EMAIL_NOT_FOUND = 'email_not_found',
  SEND_OTP_CODE_FAILURE = 'send_verification_code_failed',

  INVALID_DATE_OF_BIRTH = 'invalid_date_of_birth',
  INVALID_FIELD_VALUE = 'invalid_field_value',
  INVALID_POSTAL_CODE = 'invalid_postal_code',
  INVALID_STATE_PROVINCE = 'invalid_state_province',
  REFUND_ALREADY_SENT = 'refund_already_sent',
  UNKNOWN = 'unknown',
}

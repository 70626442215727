import {
  createLoginSession,
  ExternalUserAuthorizationToken,
} from '@hc-claims/cfar-api';
import { usePartnerContext } from '@hc-claims/ui';
import { useCallback } from 'react';
import { useMutation, UseMutationResult } from 'react-query';
import { setAuthSession } from '../../../../store/authSession';

type UseCreateLoginSessionParams = {
  sessionId: string;
  productId: string;
  onSuccess: (token: string) => void;
  onError: () => void;
};

export const useCreateLoginSession = ({
  sessionId,
  productId,
  onSuccess,
  onError,
}: UseCreateLoginSessionParams): UseMutationResult<
  ExternalUserAuthorizationToken,
  Error,
  string
> => {
  const { partnerId } = usePartnerContext();

  const loginSessionRequest = useCallback(
    (verificationCode: string) =>
      createLoginSession(partnerId, sessionId, verificationCode, productId),
    [partnerId, sessionId, productId],
  );

  return useMutation(loginSessionRequest, {
    onSuccess: (data: ExternalUserAuthorizationToken) => {
      setAuthSession(productId, data.token);
      onSuccess(data.token);
    },
    onError,
  });
};

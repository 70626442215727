export enum AirCfarCurrentPage {
  SUMMARY = 'summary',
  REGISTER = 'register',
  PAYMENT_METHODS = 'payment_methods',
  COMPLETE = 'complete',
  AUTHENTICATION = 'authenticate',
  COMPLETE_ERROR = 'complete_error',
  AUTHENTICATION_ERROR = 'authenticate_error',
  INACTIVE = 'inactive',
  VERIFICATION = 'verification',
}

export enum AirCfarPage {
  SUMMARY = 'summary',
  REGISTER = 'register',
  PAYMENT_METHODS = 'payment-methods',
  COMPLETE = 'complete',
  INACTIVE = 'inactive',
}

import { useResponsiveValue } from '@theme-ui/match-media';
import { Box, Button, Card, Flex } from 'theme-ui';

export type FooterProps = {
  onClick?: (e: any) => void;
  testID?: string;
  text: string;
  isDisabled?: boolean;
  buttonIconRight?: JSX.Element;
};

export const Footer = ({
  onClick,
  testID,
  text,
  isDisabled,
  buttonIconRight,
}: FooterProps) => {
  const cardVariant = useResponsiveValue(['ghost', 'primary']);
  const isMobile = cardVariant === 'ghost';

  const buttonStyle = isMobile
    ? {
        width: '100%',
      }
    : {};

  return (
    <>
      {isMobile && <Box sx={{ variant: 'styles.div.footerSpacerMobile' }} />}
      <Box
        data-testid="footer-container"
        sx={
          isMobile ? { variant: 'styles.div.footerContainerMobile' } : undefined
        }
      >
        <Card
          data-testid={`footer-card-${cardVariant}`}
          sx={{
            variant: `cards.footer.${cardVariant}`,
          }}
        >
          <Button
            type="submit"
            disabled={isDisabled}
            sx={buttonStyle}
            data-testid={testID}
            onClick={onClick}
          >
            <Flex
              sx={{
                variant: 'styles.div.footerButton',
                justifyContent: 'center',
              }}
            >
              {text}
              {buttonIconRight}
            </Flex>
          </Button>
        </Card>
      </Box>
    </>
  );
};

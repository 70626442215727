import { Loading } from '@hc-claims/ui';
import { getLogger } from '@hc-claims/utilities';
import { asyncWithLDProvider } from '@hc-claims/experiments';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { App } from './App';
import { initialize } from './initialize';
import './index.css';
import { LD_CLIENT_ID } from './config';
import { PartnerContextProvider } from './common/PartnerContextProvider';
import { ErrorTemplatePage } from './common/ErrorTemplate';

const root = createRoot(document.getElementById('root') as HTMLElement);

const DefaultErrorPage = () => {
  const { t } = useTranslation();
  return (
    <PartnerContextProvider>
      <ErrorTemplatePage
        title={t('error_default_title')}
        text={t('please_try_again_later')}
      />
    </PartnerContextProvider>
  );
};

initialize()
  .then(async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: LD_CLIENT_ID,
      reactOptions: {
        useCamelCaseFlagKeys: false, // to stay consistent with the key used in feature flags
      },
    });

    root.render(
      <StrictMode>
        <PartnerContextProvider>
          <Suspense fallback={<Loading isOpen />}>
            <LDProvider>
              <App />
            </LDProvider>
          </Suspense>
        </PartnerContextProvider>
      </StrictMode>,
    );
  })
  .catch((e) => {
    const logger = getLogger('Initialize');
    logger.error(`Initialization failed: ${e}`);
    root.render(<DefaultErrorPage />);
  });

import { SERVICE_DISRUPTION_FLAG, useFlags } from '@hc-claims/experiments';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { SERVICE_DISRUPTION_ROUTE } from '../router/constants';

export const useServiceDisruptionFlagCheck = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      flags[SERVICE_DISRUPTION_FLAG] &&
      !matchPath(SERVICE_DISRUPTION_ROUTE, location.pathname)
    ) {
      navigate(SERVICE_DISRUPTION_ROUTE);
    }
  }, [flags, navigate, location]);
};

import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { lazy } from 'react';
import { CompletePage, InactivePage, SummaryPage } from '../pages';
import { AIR_CFAR_ROUTE } from '../../../../router/constants';
import { AirCfarPage } from './constants';

const RegisterPage = lazy(() => import('../pages/register/RegisterPage'));
const PaymentMethodsPage = lazy(
  () => import('../pages/payment-methods/PaymentMethodsPage'),
);

export const AIR_CFAR: RouteObject = {
  path: AIR_CFAR_ROUTE,
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <Navigate to={AirCfarPage.SUMMARY} replace />,
    },
    {
      path: AirCfarPage.SUMMARY,
      element: <SummaryPage />,
    },
    {
      path: AirCfarPage.REGISTER,
      element: <RegisterPage />,
    },
    {
      path: AirCfarPage.PAYMENT_METHODS,
      element: <PaymentMethodsPage />,
    },
    {
      path: AirCfarPage.COMPLETE,
      element: <CompletePage />,
    },
    {
      path: AirCfarPage.INACTIVE,
      element: <InactivePage />,
    },
  ],
};

import {
  CreateLoginSessionRequest,
  ExternalUserAuthorizationToken,
  VerificationChannel,
} from '@hc/external-user-api-client';
import { PartnerId } from '@hc-claims/utilities';
import { getAuthApi } from '../utilities';

export const createLoginSession = async (
  partnerId: PartnerId,
  sessionId: string,
  verificationCode: string,
  contractId?: string,
): Promise<ExternalUserAuthorizationToken> => {
  const createLoginSessionRequest: CreateLoginSessionRequest = {
    contract_id: contractId || '',
    verification_channel: VerificationChannel.Email,
    verification_code: verificationCode,
  };

  return getAuthApi(partnerId, sessionId).postLoginSessions({
    hCSessionID: sessionId,
    createLoginSessionRequest,
  });
};

import { usePartnerContext } from '@hc-claims/ui';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { nameSpacesForPartner } from '../i18n/partnerIdToNamespace';

export const usePartnerTranslations: () => UseTranslationResponse<
  string[],
  undefined
> = () => {
  const { partnerId } = usePartnerContext();
  const partnerNamespaces = nameSpacesForPartner(partnerId);
  return useTranslation(partnerNamespaces);
};

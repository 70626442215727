import { PartnerId, getLogger } from '@hc-claims/utilities';
import {
  CreateLoginCodeRequest,
  VerificationChannel,
} from '@hc/external-user-api-client';
import { getAuthApi, handleAPIError } from '../utilities';

const logger = getLogger('Send Login Code');

export const sendLoginCode = async (
  partnerId: PartnerId,
  sessionId: string,
  contractId?: string,
) => {
  if (!contractId) {
    logger.error('Could not send code because missing contractId');
    return Promise.reject();
  }

  const createLoginCodeRequest: CreateLoginCodeRequest = {
    contract_id: contractId,
    verification_channel: VerificationChannel.Email,
  };

  try {
    await getAuthApi(partnerId, sessionId).postLoginCodes({
      hCSessionID: sessionId,
      createLoginCodeRequest,
    });
    return;
  } catch (error) {
    return await handleAPIError(error);
  }
};

import { ErrorCode } from './ErrorCode';

export class ApplicationError extends Error {
  code: ErrorCode;

  constructor(errorCode: ErrorCode, message?: string) {
    super(message);
    this.name = 'ApplicationError';
    this.code = errorCode;
  }
}

import { PartnerId, completeUrl, multipleDomains } from '../constants';

const supportedDomains: Record<PartnerId, string[]> = {
  [PartnerId.AGODA]: [],
  [PartnerId.DESPEGAR]: [],
  [PartnerId.HOPPER]: [],
  [PartnerId.TRAVIX_CHEAPTICKETS]: ['de', 'ch', 'nl', 'be', 'sg'],
  [PartnerId.TRAVIX_FLUGLADEN]: ['de', 'at'],
  [PartnerId.PRICELINE]: [],
  [PartnerId.TRAVIX_BUDGET_AIR]: [],
  [PartnerId.TRAVIX_VAYAMA]: [],
  [PartnerId.TRAVIX_VLIEGWINKEL]: [],
};

export const getCompleteUrl = (partnerKey: PartnerId, locale: string) => {
  const hasMultipleDomains: boolean = multipleDomains[partnerKey];
  let url = completeUrl[partnerKey];

  if (url && hasMultipleDomains) {
    const domain = locale?.split('-')?.[1] ?? '';
    const urlParts = url.split('.');

    url =
      domain && supportedDomains[partnerKey].includes(domain.toLowerCase())
        ? `${urlParts[0]}.${urlParts[1]}.${domain.toLowerCase()}`
        : completeUrl[partnerKey];
  }
  return url;
};

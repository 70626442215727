import type { Theme } from '@theme-ui/css';
import { PartnerId } from '@hc-claims/utilities';

import { theme as agodaTheme } from '../themes/agoda';
import { theme as despegarTheme } from '../themes/despegar';
import { theme as pricelineTheme } from '../themes/priceline';
import { theme as travixBudgetAirTheme } from '../themes/travixBudgetAir';
import { theme as travixCheaptickets } from '../themes/travixCheaptickets';
import { theme as travixFlugladen } from '../themes/travixFlugladen';
import { theme as travixVayama } from '../themes/travixVayama';
import { theme as travixVliegwinkel } from '../themes/travixVliegwinkel';

export const getTheme = (partnerId?: PartnerId): Theme => {
  switch (partnerId) {
    case PartnerId.AGODA:
      return agodaTheme;
    case PartnerId.DESPEGAR:
      return despegarTheme;
    case PartnerId.PRICELINE:
      return pricelineTheme;
    case PartnerId.TRAVIX_BUDGET_AIR:
      return travixBudgetAirTheme;
    case PartnerId.TRAVIX_CHEAPTICKETS:
      return travixCheaptickets;
    case PartnerId.TRAVIX_FLUGLADEN:
      return travixFlugladen;
    case PartnerId.TRAVIX_VAYAMA:
      return travixVayama;
    case PartnerId.TRAVIX_VLIEGWINKEL:
      return travixVliegwinkel;
    default:
      return pricelineTheme;
  }
};

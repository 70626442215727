import {
  getLogger,
  getSessionItem,
  sessionIdStorageKey,
  setSessionItem,
} from '@hc-claims/utilities';
import { useStore } from './store';
import { selectSessionId } from './selectors';

const logger = getLogger('Session ID');

export function setSessionId(newSessionId: string) {
  useStore.getState().setSessionId(newSessionId);
  // setting Context in logger will pass the sessionId we retrieve for all logs
  logger.setContext({ session_id: newSessionId });
  setSessionItem(sessionIdStorageKey, newSessionId);
}

export function getSessionId(): string | null {
  const sessionIdFromState = selectSessionId(useStore.getState());
  if (sessionIdFromState) {
    return sessionIdFromState;
  }
  return getSessionItem(sessionIdStorageKey);
}

import { Global } from '@emotion/react';
import toCustomProperties from '@theme-ui/custom-properties';
import { PropsWithChildren, useMemo } from 'react';
import { jsx, Theme, ThemeUIProvider as TuiThemeProvider } from 'theme-ui';
import { PartnerId } from '@hc-claims/utilities';
import { getTheme, removeInvalidKeys } from '../utilities';

export type ThemeProviderProps = {
  partnerId?: PartnerId;
};

const CustomStyles = ({ theme }: { theme: Theme }) =>
  jsx(Global, {
    styles: () => ({
      // removeInvalidKeys is nice to have which removes invalid keys such as @media (hover: hover)
      // before we transform via toCustomProperties (otherwise toCustomProperties will log as warning which is annoying)
      html: toCustomProperties(removeInvalidKeys(theme), 'theme-ui'),
    }),
  });

export const ThemeProvider = ({
  partnerId,
  children,
}: PropsWithChildren<ThemeProviderProps>) => {
  const theme = useMemo(() => getTheme(partnerId), [partnerId]);
  return (
    <>
      <CustomStyles theme={theme} />
      <TuiThemeProvider theme={theme}>{children}</TuiThemeProvider>
    </>
  );
};

import { getDeviceData, getLogger, withRetries } from '@hc-claims/utilities';
import { getSessionApi, parseRequestError } from '../utilities';

const logger = getLogger('Fetch Session ID');

async function requestSessionId(
  partnerToken: string,
): Promise<string | undefined> {
  const deviceData = getDeviceData();
  logger.info(`Creating Session with data: ${JSON.stringify(deviceData)}`);
  const session = await getSessionApi(partnerToken).postSessions({
    createSessionRequest: {
      device: deviceData,
    },
  });
  logger.info(`Successfully retrieved session ID: ${session.id}`);
  return session.id;
}

export async function fetchSessionId(partnerToken: string) {
  try {
    const sessionFetcher = () => requestSessionId(partnerToken);
    const result = await withRetries(sessionFetcher, 3);
    return result;
  } catch (e) {
    const { message } = await parseRequestError(e);
    logger.error(`Could not create session ID: ${message}`);
  }
}

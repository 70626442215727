import { Footer, Loading } from '@hc-claims/ui';
import { EventType, getLogger } from '@hc-claims/utilities';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';

import { usePartnerTranslations } from '../../hooks';
import {
  useCreateLoginSession,
  useCreateAnalyticsEvent,
} from '../../products/air/cfar/hooks';
import { AUTHENTICATION_ERROR_PAGE_ROUTE } from '../../router/constants';
import { useStore, selectSessionId } from '../../store';
import { VerificationCodeFormData } from './types';
import { VerificationCard } from './VerificationCard';
import { useAuthenticationTarget } from './useAuthenticationTarget';

const logger = getLogger('Verification Page');

export const VerificationPage = () => {
  const navigate = useNavigate();
  const { target, productId } = useAuthenticationTarget();
  const sessionId = useStore(selectSessionId);
  const { t } = usePartnerTranslations();
  const [isLoading, setIsLoading] = useState(false);

  const createLoginSession = useCreateLoginSession({
    sessionId,
    productId,
    onSuccess: () => {
      navigate(target, { replace: true });
    },
    onError: () => {
      logger.warn(
        'Failed to authenticate user login session, redirecting to authentication error page',
      );
      navigate(AUTHENTICATION_ERROR_PAGE_ROUTE, {
        replace: true,
        state: { target },
      });
    },
  });

  const form = useForm<VerificationCodeFormData>({
    defaultValues: { verificationCode: '' },
  });

  const onSubmit = form.handleSubmit((data: VerificationCodeFormData) => {
    setIsLoading(true);
    createLoginSession.mutate(data.verificationCode);
  });

  const goToNextStep = useCallback(
    (e: Event) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  useCreateAnalyticsEvent(EventType.CFAR_ENTER_VERIFICATION_PAGE, productId);

  return (
    <>
      <Box data-testid="verification-page">
        <FormProvider {...form}>
          <VerificationCard name="verificationCode" />
        </FormProvider>
        <Footer
          isDisabled={isLoading}
          testID="auth-button"
          onClick={goToNextStep}
          text={t?.('continue')}
          buttonIconRight={
            <Box sx={{ variant: 'styles.div.footerButtonContinueIcon' }} />
          }
        />
      </Box>
      <Loading testId="verification-page-loading" isOpen={isLoading} />
    </>
  );
};

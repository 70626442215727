import { ErrorCode, isEnumMember } from '@hc-claims/utilities';
import { AirCfarApiErrorCode } from '../constants';

export const getErrorCode = (code: string) => {
  if (isEnumMember(code, ErrorCode)) {
    return code;
  }

  let errorCode = ErrorCode.DEFAULT;

  switch (code) {
    case AirCfarApiErrorCode.CANCEL_BOOKING_FAILED:
    case AirCfarApiErrorCode.CANCEL_BOOKING_FAILURE:
      errorCode = ErrorCode.CANCEL_BOOKING_FAILED;
      break;
    case AirCfarApiErrorCode.CONTRACT_NOT_FOUND:
      errorCode = ErrorCode.CONTRACT_NOT_FOUND;
      break;
    case AirCfarApiErrorCode.EXERCISE_ALREADY_COMPLETED:
      errorCode = ErrorCode.CONTRACT_EXERCISE_ALREADY_COMPLETED;
      break;
    case AirCfarApiErrorCode.EXERCISE_ALREADY_PROCESSING:
      errorCode = ErrorCode.CONTRACT_EXERCISE_ALREADY_PROCESSING;
      break;
    case AirCfarApiErrorCode.EXERCISE_FAILED:
    case AirCfarApiErrorCode.EXERCISE_NOT_FOUND:
      errorCode = ErrorCode.CONTRACT_EXERCISE_FAILED;
      break;
    case AirCfarApiErrorCode.PAYMENT_FAILURE:
      errorCode = ErrorCode.PAYMENT_FAILURE;
      break;
    case AirCfarApiErrorCode.EMAIL_NOT_FOUND:
    case AirCfarApiErrorCode.SEND_OTP_CODE_FAILURE:
      errorCode = ErrorCode.INVALID_OTP_EMAIL_ADDRESS;
      break;
    default:
      errorCode = ErrorCode.DEFAULT;
  }

  return errorCode;
};

import i18next, {
  use,
  changeLanguage as changeI18nextLanguage,
  InitOptions,
} from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getI18nLanguage } from '@hc-claims/utilities';
import fetchBackend from 'i18next-fetch-backend';
import { DEFAULT_LNG, DEFAULT_NAMESPACE } from './constants';

const resourceIndex = import.meta.glob('./locales/*/*.json', {
  as: 'url',
  eager: true,
});

const config: InitOptions = {
  lng: DEFAULT_LNG,
  ns: [DEFAULT_NAMESPACE],
  defaultNS: DEFAULT_NAMESPACE,
  fallbackNS: DEFAULT_NAMESPACE,
  fallbackLng: {
    zh: ['zh-Hans', DEFAULT_LNG],
    'zh-HK': ['zh-Hant', 'zh-Hans', DEFAULT_LNG],
    'zh-TW': ['zh-Hant', 'zh-Hans', DEFAULT_LNG],
    default: [DEFAULT_LNG],
  },
  backend: {
    loadPath: (lng: string, ns: string) => {
      const resourcePath = `./locales/${lng}/${ns}.json`;
      return resourceIndex[resourcePath];
    },
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  saveMissing: true,
  missingKeyHandler: (
    _lngs: readonly string[],
    namespace: string,
    key: string,
  ) => {
    console.warn(`i18next::missingKey ${i18next.language} ${namespace} ${key}`);
  },
};

export async function changeLanguage(requestedLng: string) {
  const lng = getI18nLanguage(requestedLng);
  if (i18next.language === lng) {
    return;
  }
  changeI18nextLanguage(lng);
}

export const initializeI18n = async (locale: string) => {
  const lng = getI18nLanguage(locale);
  if (!i18next.isInitialized) {
    use(initReactI18next)
      .use(fetchBackend)
      .init({ ...config, lng });
  } else {
    // ensure lng correct
    changeLanguage(lng);
  }
};

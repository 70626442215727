import { create, StateCreator, StoreApi, UseBoundStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { CLAIMS_APP_STORAGE_KEY, PartnerId } from '@hc-claims/utilities';
import { getPartnerAuthToken } from '@hc-claims/cfar-api';
import { State } from './types';

export const DEFAULT_PARTNER = {
  partnerId: PartnerId.HOPPER,
  partnerToken: getPartnerAuthToken(PartnerId.HOPPER),
};

const INITIAL_STATE = {
  locale: 'en-US',
  partner: DEFAULT_PARTNER, // overridden when initialized
  skipPartnerCallback: false,
  userAuthSession: undefined,
  sessionId: '',
};

const rehydrateState = (
  persistedState: unknown,
  currentState: State,
): State => {
  const persisted = persistedState as State;
  return {
    ...currentState,
    ...persisted,
  };
};

const stateCreator: StateCreator<State> = (set) => ({
  ...INITIAL_STATE,
  setLocale: (locale) => set({ locale }),
  setSkipPartnerCallback: (skipPartnerCallback) => set({ skipPartnerCallback }),
  setPartner: (partner) => set({ partner }),
  setSessionId: (sessionId) => set({ sessionId }),
  setUserAuthSession: (userAuthSession) => set({ userAuthSession }),
});

const PERSIST_OPTIONS = {
  name: CLAIMS_APP_STORAGE_KEY,
  storage: createJSONStorage(() => sessionStorage),
  merge: rehydrateState,
};

export const useStore: UseBoundStore<StoreApi<State>> = create(
  persist(stateCreator, PERSIST_OPTIONS),
);

import { Outlet } from 'react-router-dom';
import { Container } from 'theme-ui';
import { Header, HeaderLogos } from '../Header';
import { useServiceDisruptionFlagCheck } from '../../hooks';

export const Layout = () => {
  useServiceDisruptionFlagCheck();

  return (
    <Container
      bg="background"
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <HeaderLogos />
      {/* Header */}
      <Header />

      {/* Main Content */}
      <Outlet />
    </Container>
  );
};

import { PartnerId } from '@hc-claims/utilities';
import {
  AGODA_SDK_AUTH_TOKEN,
  DESPEGAR_SDK_AUTH_TOKEN,
  HOPPER_SDK_AUTH_TOKEN,
  PRICELINE_SDK_AUTH_TOKEN,
  TRAVIX_BUDGET_AIR_SDK_AUTH_TOKEN,
  TRAVIX_CHEAPTICKETS_SDK_AUTH_TOKEN,
  TRAVIX_FLUGLADEN_SDK_AUTH_TOKEN,
  TRAVIX_VAYAMA_SDK_AUTH_TOKEN,
  TRAVIX_VLIEGWINKEL_SDK_AUTH_TOKEN,
} from '../config';

export const getPartnerAuthToken = (partnerId: PartnerId): string => {
  switch (partnerId) {
    case PartnerId.AGODA:
      return AGODA_SDK_AUTH_TOKEN;
    case PartnerId.DESPEGAR:
      return DESPEGAR_SDK_AUTH_TOKEN;
    case PartnerId.PRICELINE:
      return PRICELINE_SDK_AUTH_TOKEN;
    case PartnerId.TRAVIX_BUDGET_AIR:
      return TRAVIX_BUDGET_AIR_SDK_AUTH_TOKEN;
    case PartnerId.TRAVIX_CHEAPTICKETS:
      return TRAVIX_CHEAPTICKETS_SDK_AUTH_TOKEN;
    case PartnerId.TRAVIX_FLUGLADEN:
      return TRAVIX_FLUGLADEN_SDK_AUTH_TOKEN;
    case PartnerId.TRAVIX_VAYAMA:
      return TRAVIX_VAYAMA_SDK_AUTH_TOKEN;
    case PartnerId.TRAVIX_VLIEGWINKEL:
      return TRAVIX_VLIEGWINKEL_SDK_AUTH_TOKEN;
    default:
      return HOPPER_SDK_AUTH_TOKEN;
  }
};

interface ErrorResponse {
  message: string;
  code?: string;
}

// Generated API client throws non-2xx responses but other errors are also possible
export const parseRequestError = async (
  value: unknown,
): Promise<ErrorResponse> => {
  let message = 'unexpected request error';
  let code;

  // Includes instanceof Error
  if (!(value instanceof Response)) {
    message = `${message}: ${value}`;
    return { message, code };
  }

  const contentType = value.headers.get('content-type');
  message = `unknown failure: status ${value.status}`;

  if (contentType === 'application/json') {
    const body = await value.json();
    if (Array.isArray(body) && body.length > 0) {
      [{ message, code }] = body;
    }
  } else {
    message = await value.text();
  }
  return {
    code,
    message,
  };
};

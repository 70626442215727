import { getLogger } from '../logger';
import { isJSONString } from '../string/isJSONString';

const SESSION_STORAGE = 'sessionStorage';

const sessionStorage = window[SESSION_STORAGE];

export const setSessionItem = (key: string, data: string) => {
  const logger = getLogger(SESSION_STORAGE);
  try {
    sessionStorage.setItem(key, data);
  } catch (e) {
    logger.error(`failed to store ${key}: ${e}`);
  }
};

export const getSessionItem = <T>(key: string): T | null => {
  const logger = getLogger(SESSION_STORAGE);
  try {
    const data = sessionStorage.getItem(key);
    if (!data) {
      return null;
    }
    if (isJSONString(data)) {
      return JSON.parse(data);
    }
    return data as T;
  } catch (e) {
    logger.error(`failed to retrieve ${key}: ${e}`);
  }
  return null;
};

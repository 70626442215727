import { getLang } from '../getLang';

enum SupportedLanguages {
  'da',
  'de',
  'en',
  'en-GB',
  'en-CA',
  'es-ES',
  'fr',
  'it',
  'nl',
  'pl',
  'pt',
  'sv',
}

const getLangAsEnum = (lang: string): SupportedLanguages =>
  SupportedLanguages[lang as keyof typeof SupportedLanguages];

const convertEnumToLang = (supportLang: string): string => {
  return supportLang.toLowerCase().replace('-', '_');
};

export const getCXLang = (locale: string = 'en'): string => {
  const lang = getLangAsEnum(locale);
  if (lang !== undefined) return convertEnumToLang(SupportedLanguages[lang]);

  const localeRoot = getLang(locale);
  const langRoot = getLangAsEnum(localeRoot);
  if (langRoot !== undefined)
    return convertEnumToLang(SupportedLanguages[langRoot]);

  // Make exception for ES
  if (localeRoot === 'es') return convertEnumToLang('es-ES');

  console.warn(`Could not find language for ${locale}, defaulting to 'en'`);
  return 'en';
};

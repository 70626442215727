import { ApplicationError, getLogger } from '@hc-claims/utilities';
import { parseRequestError } from './parseRequestError';
import { getErrorCode } from './getErrorCode';

const logger = getLogger('Air CFAR API');

export async function handleAPIError(error: unknown) {
  if (!(error instanceof Response)) {
    return Promise.reject(error);
  }
  const { code = '', message } = await parseRequestError(error);
  logger.error(message);
  return Promise.reject(new ApplicationError(getErrorCode(code), message));
}

import { formatCurrency } from '@hc-claims/utilities';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Flex, Heading, Text } from 'theme-ui';
import { usePartnerContext } from '../hooks';
import MoneyIcon from '../../assets/money.svg?react';

export type CashRefundCardProps = {
  total?: string;
  currency?: string;
  locale?: string;
  highlight?: boolean;
  percentage?: number | string;
  variant?: string;
};

export const CashRefundCard = ({
  total,
  currency,
  locale,
  percentage,
  variant,
}: CashRefundCardProps) => {
  const { partnerId } = usePartnerContext();
  const { t } = useTranslation([partnerId, 'common']);

  const formattedContractCoverage = useMemo(
    () => formatCurrency(total, currency, locale),
    [total, currency, locale],
  );

  return (
    <Card
      data-testid="cash-refund-card"
      sx={{
        borderBottom: '0.5px solid #D9D9D9',
        variant: variant || 'cards.cashRefundCard',
      }}
    >
      <Flex
        data-testid="cash-refund-flex"
        sx={{ flex: 1, gap: 8, variant: 'styles.div.cashRefund.flex' }}
      >
        <Box
          data-testid="money-icon-box"
          sx={{
            color: 'primary',
            minWidth: 'auto',
            variant: 'styles.icons.cashRefund.moneyIcon',
          }}
        >
          <MoneyIcon />
        </Box>
        <Box>
          <Heading
            data-testid="cash-refund-card-title"
            as="h3"
            variant="heading"
          >
            {t?.('cash_refund_title', {
              refund: formattedContractCoverage,
            })}
          </Heading>
          <Text data-testid="cash-refund-card-description">
            {t?.('cash_refund_description', {
              percentage: percentage,
              refund: formattedContractCoverage,
            })}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

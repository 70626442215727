import { CashRefundCard, FlightCard, Footer, StatusBadge } from '@hc-claims/ui';
import {
  EventType,
  Product,
  formatDatetimeWithTimezone,
  formatPercent,
} from '@hc-claims/utilities';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FormEventHandler,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Label,
  Link,
  Paragraph,
  Text,
} from 'theme-ui';
import CfarIcon from '../../../../../../assets/cfar.svg?react';
import { HYPERWALLET_TC_URL } from '../../../../../config';
import { usePartnerTranslations } from '../../../../../hooks';
import { useStore } from '../../../../../store';
import { AirCfarPage } from '../../routes/constants';
import { useCreateAnalyticsEvent, useValidateExercise } from '../../hooks';
import { productFromRoute } from '../../../../../router/routeForProduct';
import { useShutOffAutomaticExerciseFlagCheck } from '../../../../../hooks/useShutOffAutomaticExerciseFlagCheck';

export const SummaryPage = () => {
  const navigate = useNavigate();
  const { t } = usePartnerTranslations();
  const location = useLocation();

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [showError, setShowError] = useState(false);
  const { data: contract } = useValidateExercise();

  const { productId } = productFromRoute(location) as Product;

  const errorRef = useRef<null | HTMLDivElement>(null);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();

      if (termsAndConditions) {
        navigate(`../${AirCfarPage.REGISTER}`);
      } else {
        setShowError(true);
      }
    },
    [navigate, termsAndConditions],
  );

  useEffect(() => {
    errorRef.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, [showError]);

  const locale = useStore.getState().locale;
  const passengerCount = contract?.purchase_quote?.fare?.passengers?.length;
  const purchaseQuote = contract?.purchase_quote;

  const contractPurchaseOptionId = purchaseQuote?.purchase_option_id;
  const coveragePercentage = contractPurchaseOptionId
    ? purchaseQuote?.offer?.purchase_options.find(
        (opt) => opt.id === contractPurchaseOptionId,
      )?.coverage_percentage
    : '';

  const contractExpiryDate = purchaseQuote?.offer?.contract_expiry!;
  const expiryDate = formatDatetimeWithTimezone(contractExpiryDate, locale);

  const formattedPercentage = useMemo(
    () => (coveragePercentage ? formatPercent(coveragePercentage, locale) : ''),
    [coveragePercentage, locale],
  );

  const handleCheckbox = useCallback(() => {
    if (termsAndConditions) {
      setTermsAndConditions(false);
    } else {
      setTermsAndConditions(true);
      setShowError(false);
    }
  }, [termsAndConditions, setTermsAndConditions]);

  useCreateAnalyticsEvent(EventType.CFAR_ENTER_CONTRACT_PAGE, productId);

  useShutOffAutomaticExerciseFlagCheck();
  return (
    <Box data-testid="summary-page">
      <Card sx={{ variant: 'cards.summaryPage.baseCard' }}>
        <Flex
          sx={{
            mb: 4,
            alignItems: 'center',
            justifyContent: 'space-between',
            variant: 'styles.icons',
          }}
        >
          <Flex>
            <CfarIcon height={30} width={30} />
            <Heading as="h2" variant="highlight" sx={{ alignSelf: 'center' }}>
              <Flex sx={{ alignItems: 'center' }}>
                <Box pl={3} sx={{ flex: '1 1 auto' }}>
                  {t?.('cancel_for_any_reason')}
                </Box>
              </Flex>
            </Heading>
          </Flex>

          <StatusBadge status={contract?.state ?? ''} t={t} />
        </Flex>
        <Paragraph mb={3} pl={2}>
          <Trans
            i18nKey="cancel_your_booking"
            values={{
              expiryDate,
              percentage: formattedPercentage,
            }}
            components={[<Text key="highlight" variant="text.bold" />]}
          />
        </Paragraph>
        <Paragraph pl={2}>{t?.('receive_a_confirmation_email')}</Paragraph>
      </Card>
      <Divider mt={0} mb={0} ml={16} mr={16} data-testid="divider" />
      <FlightCard
        locale={locale}
        slices={contract?.purchase_quote?.fare?.slices}
        passengerCount={passengerCount}
        bookingLocators={contract?.booking_locators}
        sx={{
          borderBottom: '0.5px solid #D9D9D9',
          variant: 'cards.summaryPage.flightCard',
        }}
      />
      <CashRefundCard
        highlight
        total={contract?.purchase_quote?.total_coverage}
        currency={contract?.purchase_quote?.offer?.currency}
        percentage={formattedPercentage}
        locale={locale}
      />
      <Card mb={3} pt={16} p={0}>
        <form onSubmit={onSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'row',
              ml: 32,
              variant: 'styles.div.summaryPage.termsAndConditions',
            }}
          >
            <Label>
              <Checkbox
                data-testid="tc-checkbox"
                checked={termsAndConditions}
                onChange={handleCheckbox}
              />
              <Box sx={{ fontSize: '16px', textTransform: 'none' }}>
                <Trans
                  i18nKey="i_agree_to_the_terms_and_conditions"
                  components={[
                    <Link
                      key="tc-link"
                      href={HYPERWALLET_TC_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              </Box>
            </Label>
          </Box>
          {showError && (
            <Box
              ref={errorRef}
              data-testid="tc-error"
              sx={{
                ml: 34,
                variant: 'styles.div.summaryPage.termsAndConditionsError',
              }}
            >
              <Text variant="text.error">
                {t?.('error_terms_and_conditons')}
              </Text>
            </Box>
          )}
          <Footer
            testID="home-button"
            text={t?.('continue')}
            buttonIconRight={
              <Box sx={{ variant: 'styles.div.footerButtonContinueIcon' }} />
            }
          />
        </form>
      </Card>
    </Box>
  );
};

import '@fontsource/rubik';
import { merge, Theme } from 'theme-ui';
import htsLogo from '../../assets/htsLogo.svg';
import despegarLogo from '../../assets/despegarLogo.svg';
import checkIcon from '../../assets/despegar/checkmark.svg';
import { theme as defaultTheme } from './default';

const PRIMARY_COLOR = '#4300D2';
const PRIMARY_HOVER_COLOR = '#270570';
const SECONDARY_HOVER_COLOR = '#EBE5F5';
const HIGHLIGHT_COLOR = '#343A3D';

const BUTTON_STYLE = {
  // shape & position
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '32px',
  width: 'auto',

  // text
  /* Subtitle M (H6) */
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.014px',
};

// link button style
const SECONDARY_BUTTON_STYLE = {
  ...BUTTON_STYLE,
  background: 'white',
  // NOTE: using PRIMARY_COLOR instead of 'primary' because when showing hyperwallet widgets
  //  for some reason 'primary' didn't resolve to PRIMARY_COLOR
  color: PRIMARY_COLOR,
  border: 'none',

  '@media (hover: hover)': {
    '&:hover:enabled': {
      background: 'secondaryHoverBg',
    },
  },
};

export const theme: Theme = merge(defaultTheme, {
  fonts: {
    body: 'Rubik',
    heading: 'Rubik',
  },
  text: {
    highlight: {
      color: HIGHLIGHT_COLOR,
    },
  },
  colors: {
    primary: PRIMARY_COLOR,
    primaryHoverBg: PRIMARY_HOVER_COLOR,
    secondaryHoverBg: SECONDARY_HOVER_COLOR,
    accent: PRIMARY_COLOR,
    background: 'FFFFFF',
    inputOutline: PRIMARY_COLOR,
  },
  buttons: {
    primary: {
      ...BUTTON_STYLE,
      backgroundColor: 'primary',
      color: 'white',

      '@media (hover: hover)': {
        '&:hover:enabled': {
          background: 'primaryHoverBg',
        },
      },
    },
    secondary: SECONDARY_BUTTON_STYLE,
    errorPage: {
      leftButton: SECONDARY_BUTTON_STYLE,
      rightButton: {
        width: 'auto',
      },
    },
    contactUsPage: {
      faq: SECONDARY_BUTTON_STYLE,
      contactUs: {
        width: 'auto',
      },
    },
  },
  cards: {
    registerPage: {
      registerRecipientForm: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    authenticationPage: {
      welcomeCard: {
        paddingBottom: 0,
      },
    },
    verificationPage: {
      verificationCard: {
        paddingBottom: 0,
      },
    },
  },
  forms: {
    input: {
      borderRadius: '6px',
      '&:focus': {
        outlineColor: PRIMARY_COLOR,
      },
    },
    select: {
      borderRadius: '6px',
      '&:focus': {
        outlineColor: PRIMARY_COLOR,
      },
    },
  },
  styles: {
    // override default hoverGradient to none so that we can apply partner's hover colors without gradient
    hoverGradient: {
      backgroundImage: 'none',
    },
    div: {
      headerLogosContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 32px',
        pb: 3,
        backgroundColor: 'transparent',
        gap: '8px',
      },
      contactUs: {
        display: 'flex',
        justifyContent: 'end',
      },
      footerSpacerMobile: {
        display: 'none',
      },
      footerContainerMobile: {
        position: 'relative',
      },
    },
    logos: {
      leftLogo: {
        backgroundImage: `url(${despegarLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '110px',
        height: '24px',
      },
      rightLogo: {
        backgroundImage: `url(${htsLogo})`,
        backgroundRepeat: 'no-repeat',
        width: '92px',
        height: '24px',
      },
    },
    icons: {
      checkIcon: {
        backgroundImage: `url(${checkIcon})`,
      },
    },
  },
  radii: {
    radius: '6px',
  },
});

import { formatDate, formatTime } from '@hc-claims/utilities';
import { CfarFareSlice, CfarFareSliceSegment } from '@hc-claims/cfar-api';
import isEmpty from 'lodash-es/isEmpty';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Paragraph, ThemeUIStyleObject } from 'theme-ui';
import AirplaneLeftIcon from '../../../assets/airplane-left.svg?react';
import AirplaneRightIcon from '../../../assets/airplane-right.svg?react';
import UserIcon from '../../../assets/user.svg?react';
import { usePartnerContext } from '../../hooks';
import { BookingLocator } from '../BookingLocator';

export interface FlightCardRowProps {
  isOutbound?: boolean;
  slice?: CfarFareSlice;
  passengerCount?: number;
  locale?: string;
  bookingLocators?: string[];
}

export const FlightCardRow = ({
  isOutbound,
  slice,
  passengerCount,
  bookingLocators,
  locale = 'en',
}: FlightCardRowProps) => {
  const { partnerId } = usePartnerContext();
  const { t } = useTranslation([partnerId, 'common']);
  if (isEmpty(slice?.segments) || slice?.segments === undefined) {
    return null;
  }

  const airplaneIcon = isOutbound ? (
    <AirplaneRightIcon />
  ) : (
    <AirplaneLeftIcon />
  );

  const iconStyle: ThemeUIStyleObject = { color: 'primary' };
  const flexStyle: ThemeUIStyleObject = { flex: 1, gap: 8 };
  return (
    <Flex>
      <Flex data-testid="flight-card-row" sx={flexStyle} mb={4}>
        <Box sx={iconStyle}>{airplaneIcon}</Box>
        <Box>
          {slice.segments.map(
            (segment: CfarFareSliceSegment, index: number) => (
              <Box key={index} mt={1}>
                <Box mb={2}>
                  <Heading as="h3" variant="heading">
                    {segment?.origin_airport} to {segment?.destination_airport}
                  </Heading>
                </Box>
                <Paragraph data-testid="departure-date">
                  {formatDate(segment?.departure_date_time, locale)}
                </Paragraph>
                <Paragraph data-testid="departure-time">
                  {formatTime(segment?.departure_date_time, locale)}
                </Paragraph>
                <Paragraph mb={4}>
                  <BookingLocator bookingLocators={bookingLocators} />
                </Paragraph>
              </Box>
            ),
          )}
        </Box>
      </Flex>
      <Flex pl={5} sx={flexStyle}>
        <Box sx={iconStyle}>
          <UserIcon />
        </Box>
        <Box mt={2}>
          <Heading as="h3" variant="heading" data-testid="passenger-count">
            {`${passengerCount} ${
              passengerCount && passengerCount > 1
                ? t?.('passengers')
                : t?.('passenger')
            }`}
          </Heading>
        </Box>
      </Flex>
    </Flex>
  );
};

import { sendLoginCode } from '@hc-claims/cfar-api';
import { Footer, Loading, usePartnerContext } from '@hc-claims/ui';
import { EventType } from '@hc-claims/utilities';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Flex, Heading, Paragraph } from 'theme-ui';

import { useMutation } from 'react-query';
import { usePartnerTranslations } from '../../hooks';
import { VERIFICATION_ROUTE } from '../../router/constants';
import { selectSessionId, useStore } from '../../store';
import { useCreateAnalyticsEvent } from '../../products/air/cfar/hooks/useCreateAnalyticsEvent';
import { useAuthenticationTarget } from './useAuthenticationTarget';

export const AuthenticationPage = () => {
  const navigate = useNavigate();

  const { productId, target } = useAuthenticationTarget();
  const { partnerId } = usePartnerContext();

  const { t } = usePartnerTranslations();
  const sessionId = useStore(selectSessionId);

  const {
    mutate: sendVerificationCode,
    isLoading,
    isSuccess,
  } = useMutation({
    mutationFn: () => sendLoginCode(partnerId, sessionId, productId),
  });

  if (isSuccess) {
    navigate(VERIFICATION_ROUTE, { state: { target } });
  }

  const goToNextStep = useCallback(
    (e: Event) => {
      e.preventDefault();
      sendVerificationCode();
    },
    [sendVerificationCode],
  );

  useCreateAnalyticsEvent(EventType.CFAR_ENTER_AUTHENTICATE_PAGE, productId);

  return (
    <>
      <Box data-testid="authentication-page">
        <Card sx={{ variant: 'cards.authenticationPage.welcomeCard' }}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Heading as="h2" mb={4} data-testid="email-auth-title">
              {t?.('welcome_title')}
            </Heading>
            <Box
              data-testid="header-icon"
              sx={{ variant: 'styles.div.authenticationPage.headerIcon' }}
            />
          </Flex>
          <Paragraph mb={5} data-testid="email-auth-description">
            {t?.('to_proceed')}
          </Paragraph>
        </Card>
        <Footer
          isDisabled={isLoading}
          testID="auth-button"
          onClick={goToNextStep}
          text={t?.('continue')}
          buttonIconRight={
            <Box sx={{ variant: 'styles.div.footerButtonContinueIcon' }} />
          }
        />
      </Box>
      <Loading testId="authentication-page-loading" isOpen={isLoading} />
    </>
  );
};

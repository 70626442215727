export const BookingLocator = ({
  bookingLocators,
}: {
  bookingLocators: string[] | undefined;
  condensed?: boolean;
}) => {
  if (!bookingLocators || bookingLocators.length === 0) {
    return null;
  }

  return (
    <span data-testid="booking-locators">
      {bookingLocators.map((bookingLocator, index) => (
        <span key={index}>
          <span key={bookingLocator}>{bookingLocator}</span>
          {index <= bookingLocators.length - 2 && ', '}
        </span>
      ))}
    </span>
  );
};

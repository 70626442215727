// SDK AUTH TOKENS
export const HOPPER_SDK_AUTH_TOKEN =
  import.meta.env.VITE_HOPPER_SDK_AUTH_TOKEN || '';
export const AGODA_SDK_AUTH_TOKEN =
  import.meta.env.VITE_AGODA_SDK_AUTH_TOKEN || '';
export const DESPEGAR_SDK_AUTH_TOKEN =
  import.meta.env.VITE_DESPEGAR_SDK_AUTH_TOKEN || '';
export const PRICELINE_SDK_AUTH_TOKEN =
  import.meta.env.VITE_PRICELINE_SDK_AUTH_TOKEN || '';
export const TRAVIX_BUDGET_AIR_SDK_AUTH_TOKEN =
  import.meta.env.VITE_TRAVIX_BUDGET_AIR_SDK_AUTH_TOKEN || '';
export const TRAVIX_CHEAPTICKETS_SDK_AUTH_TOKEN =
  import.meta.env.VITE_TRAVIX_CHEAPTICKETS_SDK_AUTH_TOKEN || '';
export const TRAVIX_FLUGLADEN_SDK_AUTH_TOKEN =
  import.meta.env.VITE_TRAVIX_FLUGLADEN_SDK_AUTH_TOKEN || '';
export const TRAVIX_VAYAMA_SDK_AUTH_TOKEN =
  import.meta.env.VITE_TRAVIX_VAYAMA_SDK_AUTH_TOKEN || '';
export const TRAVIX_VLIEGWINKEL_SDK_AUTH_TOKEN =
  import.meta.env.VITE_TRAVIX_VLIEGWINKEL_SDK_AUTH_TOKEN || '';

export const API_URL = import.meta.env.VITE_API_URL || '';
export const PARTNER_API_URL = `${API_URL}/partner/air/v4.0`;
export const EXTERNAL_USER_API_URL = `${API_URL}/external_user/v1.0`;

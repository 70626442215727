import { getLogger } from '../logger';

const logger = getLogger('retries');

export async function withRetries<T>(func: () => Promise<T>, maxAttempts = 3) {
  for (let attempt = 1; attempt <= maxAttempts; attempt += 1) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const res = await func();
      return res;
    } catch (err) {
      if (attempt < maxAttempts) {
        logger.error(`Failed in attempt #${attempt} with ${func.name}. ${err}`);
      } else {
        throw new Error(`Failed after ${maxAttempts} attempts: ${err}`);
      }
    }
  }
}

import { ContactUsUrl, PartnerId } from '../constants';
import { getCXLang } from '../getCXLang';

export const generateContactUsUrl = (partnerId: PartnerId, locale: string) => {
  const lang = getCXLang(locale);

  const url = partnerId
    ? ContactUsUrl[partnerId as PartnerId]
    : ContactUsUrl[PartnerId.PRICELINE];

  // Contact Us form is only supported for EN, FR & ES
  if (lang === 'en' || (lang !== 'fr' && lang !== 'es_es'))
    return url.replace('{{lang}}', '');
  return url.replace('{{lang}}', `${lang}/`);
};

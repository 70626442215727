import '@fontsource/roboto';
import { merge, Theme } from 'theme-ui';
import htsLogo from '../../assets/htsLogo.svg';
import continueIcon from '../../assets/cheaptickets/continue.svg';
import dropdownIcon from '../../assets/cheaptickets/dropdown.svg';
import { theme as defaultTheme } from './default';

const PRIMARY_COLOR = '#004B94';
const PRIMARY_HOVER_COLOR = '#E6BB00';
const SECONDARY_HOVER_COLOR = '#EBF5FD';
const ACCENT_COLOR = '#FFF23E';
const SUCCESS_COLOR = '#00B250';
const WARNING_COLOR = '#E85046';
const ERROR_COLOR = '#D2373F';
const INFO_COLOR = '#00B0FF';
const HEADING_COLOR = '#29425B';
const TEXT_COLOR = '#597385';
const BACKGROUND_COLOR = '#D5E8F8';
const BUTTON_FONT_COLOR = '#003F7F';
const BACK_BUTTON_COLOR = '#7EA6D4';
const BORDER_COLOR = '#ADC9EA';

const RIGHT_BUTTON_BACKGROUND = `linear-gradient(180deg, ${ACCENT_COLOR} 0%, #F7DE00 100%)`;
const RIGHT_BUTTON_BOX_SHADOW = `0px 3px 3px rgba(0, 0, 0, 0.15), inset 0px -3px 0px ${PRIMARY_HOVER_COLOR}`;
const LEFT_BUTTON_BOX_SHADOW = `0px 3px 3px rgba(0, 0, 0, 0.15), inset 0px -3px 0px ${SECONDARY_HOVER_COLOR}`;

export const theme: Theme = merge(defaultTheme, {
  colors: {
    background: BACKGROUND_COLOR,
    primary: PRIMARY_COLOR,
    primaryHoverBg: PRIMARY_HOVER_COLOR,
    secondaryHoverBg: SECONDARY_HOVER_COLOR,
    accent: ACCENT_COLOR,
    text: TEXT_COLOR,
    heading: HEADING_COLOR,
    success: SUCCESS_COLOR,
    warning: WARNING_COLOR,
    error: ERROR_COLOR,
    info: INFO_COLOR,
    backButton: BACK_BUTTON_COLOR,
    borderColor: BORDER_COLOR,
  },
  buttons: {
    primary: {
      width: '100%',
      background: RIGHT_BUTTON_BACKGROUND,
      boxShadow: RIGHT_BUTTON_BOX_SHADOW,
      borderRadius: '4px',
      color: BUTTON_FONT_COLOR,
      fontFamily: 'Khand-bold',
      fontWeight: '700',
      lineHeight: '32px',
      fontStyle: 'normal',
      fontSize: ['16px', '24px'],
      paddingLeft: '0px',
      paddingRight: '0px',

      '@media (hover: hover)': {
        '&:hover:enabled': {
          background: 'primaryHoverBg',
        },
      },
    },
    secondary: {
      width: '100%',
      background: '#FFFFFF',
      boxShadow: LEFT_BUTTON_BOX_SHADOW,
      border: '1px solid',
      borderColor: BORDER_COLOR,
      borderRadius: '4px',
      color: BUTTON_FONT_COLOR,
      fontFamily: 'Khand-semibold',
      fontWeight: '600',
      lineHeight: '32px',
      fontStyle: 'normal',
      fontSize: ['16px', '24px'],
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    errorPage: {
      rightButton: {
        width: '100%',
        background: RIGHT_BUTTON_BACKGROUND,
        boxShadow: RIGHT_BUTTON_BOX_SHADOW,
        borderRadius: '4px',
        color: BUTTON_FONT_COLOR,
        fontFamily: 'Khand-bold',
        fontWeight: '700',
        lineHeight: '32px',
        fontStyle: 'normal',
        fontSize: ['16px', '24px'],
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      leftButton: {
        width: '100%',
        background: '#FFFFFF',
        boxShadow: LEFT_BUTTON_BOX_SHADOW,
        border: '1px solid',
        borderColor: BORDER_COLOR,
        borderRadius: '4px',
        color: BUTTON_FONT_COLOR,
        fontFamily: 'Khand-semibold',
        fontWeight: '600',
        lineHeight: '32px',
        fontStyle: 'normal',
        fontSize: ['16px', '24px'],
        paddingLeft: '0px',
        paddingRight: '0px',
      },
    },
    contactUsPage: {
      faq: {
        color: BUTTON_FONT_COLOR,
        fontFamily: 'Khand-semibold',
        fontWeight: '600',
        lineHeight: '32px',
        fontStyle: 'normal',
        fontSize: ['16px', '24px'],
        boxShadow: LEFT_BUTTON_BOX_SHADOW,
      },
    },
  },
  layout: {
    container: {
      pt: 0,
    },
  },
  cards: {
    primary: {
      marginBottom: 1,
      pt: 24,
      pb: 24,
      pl: 16,
      pr: 16,
    },
    ghost: {
      marginBottom: 1,
    },
    footer: {
      primary: {
        width: '100%',
        paddingLeft: 16,
        marginBottom: 1,
        p: 16,
      },
      ghost: {
        marginBottom: '2px',
      },
    },
    errorTemplate: {
      pt: 16,
      display: 'flex',
      flexDirection: 'column',
    },
    summaryPage: {
      flightCard: {
        mb: 0,
        borderBottom: 0,
        pb: 0,
        mt: -1,
      },
      baseCard: {
        mb: 0,
      },
    },
    cashRefundCard: {
      width: '100%',
      p: 16,
      pt: 0,
      mb: '1px',
    },
    registerPage: {
      cashRefundCard: {
        display: 'flex',
        backgroundColor: 'transparent',
        p: 16,
        borderBottom: 'none',
      },
      headingCard: {
        mb: 0,
      },
      registerRecipientForm: {
        p: 16,
        pt: 0,
      },
    },
  },
  text: {
    default: {
      color: TEXT_COLOR,
    },
    error: {
      color: TEXT_COLOR,
      fontSize: '14px',
    },
    heading: {
      color: HEADING_COLOR,
      verifyYourEmail: {
        display: 'none',
      },
      errorTemplate: {
        title: {
          display: 'none',
        },
        message: {
          pb: 0,
        },
      },
      register: {
        display: 'none',
      },
      paymentMethodsPage: {
        mb: '24px',
        fontSize: '16px',
      },
    },
    header: {
      color: '#0056A9',
      textAlign: 'center',
      pl: 0,
    },
    highlight: {
      color: HEADING_COLOR,
      fontSize: '16px',
    },
  },
  forms: {
    input: {
      color: 'black',
      border: '2px solid',
      borderColor: BORDER_COLOR,
      borderRadius: '4px',
    },
    label: {
      color: TEXT_COLOR,
      fontSize: '16px',
      mt: 4,
      mb: 2,
    },
    select: {
      color: 'black',
      border: '2px solid',
      borderColor: BORDER_COLOR,
      borderRadius: '4px',
      hyperwalletDropdownIcon: {
        backgroundImage: `url(${dropdownIcon})`,
      },
      dropdownIcon: {
        backgroundImage: `url(${dropdownIcon})`,
        width: '36px',
        height: '24px',
      },
    },
    hyperwallet: {
      groupWithTemplate: {
        marginBottom: '24px',
      },
      transferMethodInfoGroup: {
        fontSize: '14px',
        padding: '16px',
        background: SECONDARY_HOVER_COLOR,
        marginBottom: '24px',
        boxShadow: '0px 2px 3px rgba(0, 63, 127, 0.15)',
        borderRadius: '4px',
        marginTop: '-24px',
      },
    },
  },
  styles: {
    // override default hoverGradient to none so that we can apply partner's hover colors without gradient (and also cheapticket border shadow)
    hoverGradient: {
      backgroundImage: 'none',
    },
    hr: {
      display: 'flex',
      border: '1px dashed #BDD8F1',
      transform: 'matrix(1, 0, 0, -1, 0, 0);',
      flex: 'none',
      mb: 32,
    },
    root: {
      legend: {
        color: HEADING_COLOR,
      },
    },
    div: {
      cashRefund: {
        flex: {
          p: 16,
          backgroundColor: '#E6FCF0',
          borderRadius: 4,
        },
      },
      errorTemplate: {
        flexDirection: 'column-reverse',
        backgroundColor: '#FFFFFF',
        padding: 16,
        bottom: 'none',
        marginTop: 0,
        errorIcon: {
          alignSelf: 'center',
        },
      },
      fieldErrorMessage: {
        backgroundColor: '#F6DDE1',
        p: '12px',
        borderRadius: '4px',
      },
      footerButton: {
        width: '100%',
        gap: '8px',
        alignItems: 'center',
        fontSize: ['16px', '24px'],
      },
      footerButtonContinueIcon: {
        backgroundImage: `url(${continueIcon})`,
        backgroundRepeat: 'no-repeat',
        width: '20px', // using width by height dimensions of svg
        height: '18px',
        paddingBottom: '20px',
      },
      authenticationPage: {
        headerIcon: {
          backgroundImage: `url(${htsLogo})`,
          backgroundRepeat: 'no-repeat',
          width: '92px', // headerIcon is 92px, so using that as width
        },
      },
      summaryPage: {
        termsAndConditionsError: {
          backgroundColor: '#F6DDE1',
          p: 12,
          borderRadius: '4px',
          ml: 16,
          mr: 16,
        },
        termsAndConditions: {
          ml: 16,
        },
      },
      registerPage: {
        addressSection: {
          display: 'flex',
          flexDirection: 'column',
        },
      },
      verificationPage: {
        spamBadge: {
          display: 'none',
        },
      },
      headerBackButtonBox: {
        pl: 8,
      },
      contactUs: {
        position: 'initial',
        flexDirection: 'column-reverse',
        backgroundColor: 'white',
        pt: 16,
        pb: 16,
        mt: 0,
      },
      statusBadge: {
        active: {
          display: 'block',
          backgroundColor: '#E6FCF0',
          color: '#00B250',
          p: '4px 12px',
          borderRadius: 4,
          fontWeight: 700,
          height: 'fit-content',
        },
        expired: {
          display: 'block',
          backgroundColor: '#FFF2E4',
          color: '#EC8A18',
          p: '4px 12px',
          borderRadius: 4,
          fontWeight: 700,
          height: 'fit-content',
        },
        unavailable: {
          display: 'block',
          backgroundColor: '#FFF2E4',
          color: '#EC8A18',
          p: '4px 12px',
          borderRadius: 4,
          fontWeight: 700,
          height: 'fit-content',
        },
      },
    },
    icons: {
      color: PRIMARY_COLOR,
      cashRefund: {
        moneyIcon: {
          display: 'none',
        },
      },
    },
  },
  breakpoints: ['400px'],
  radii: {
    radius: '4px',
  },
});

import { getLogger } from '@hc-claims/utilities';

import { hoursFromNow } from '../utilities/hoursFromNow';
import { useStore } from './store';
import { AuthSession } from './types';

const logger = getLogger('Auth Session');

const isExpired = ({ expiry }: AuthSession) => new Date().getTime() > expiry;

export function getAuthToken(productId: string): string | undefined {
  const { userAuthSession, setUserAuthSession } = useStore.getState();

  if (!userAuthSession) {
    return undefined;
  }

  // if userAuthSession is expired or if either productId
  // does not match the auth session we store in memory (user might be trying to exercise another productId)
  // we will reset userAuthSession and return undefined which should force user to re-authenticate via useAuthCheck
  if (isExpired(userAuthSession) || productId !== userAuthSession.productId) {
    setUserAuthSession(undefined);
    return undefined;
  }

  return userAuthSession.authToken;
}

export function setAuthSession(productId: string, authToken: string) {
  // auth token last for 1 hour per session
  const expiry = hoursFromNow(1);
  const { setUserAuthSession } = useStore.getState();

  if (!productId) {
    logger.error('attempt to store without productId');
    return;
  }

  const authSession = { authToken, expiry, productId };
  setUserAuthSession(authSession);
}

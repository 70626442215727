import { Card, CardProps, Flex, ThemeUIStyleObject } from 'theme-ui';
import { CfarFareSlice } from '@hc-claims/cfar-api';
import isEmpty from 'lodash-es/isEmpty';
import { FlightCardRow } from './FlightCardRow';
export interface FlightCardProps extends CardProps {
  slices?: CfarFareSlice[];
  passengerCount?: number;
  useCard?: boolean;
  locale?: string;
  bookingLocators?: string[];
}

export const FlightCard = ({
  slices,
  passengerCount,
  useCard = true,
  locale,
  bookingLocators,
  ...rest
}: FlightCardProps) => {
  const outbound = slices?.[0];
  const returning = slices?.[1];

  const flexStyle: ThemeUIStyleObject = { flexDirection: 'column' };

  return useCard ? (
    <Card data-testid="flight-card" {...rest}>
      <Flex sx={flexStyle}>
        {!isEmpty(outbound) && (
          <FlightCardRow
            isOutbound
            locale={locale}
            slice={outbound}
            passengerCount={passengerCount}
            bookingLocators={bookingLocators}
          />
        )}
        {!isEmpty(returning) && (
          <>
            <FlightCardRow
              locale={locale}
              slice={returning}
              passengerCount={passengerCount}
              bookingLocators={bookingLocators}
            />
          </>
        )}
      </Flex>
    </Card>
  ) : (
    <Flex sx={flexStyle}>
      {!isEmpty(outbound) && (
        <FlightCardRow
          locale={locale}
          isOutbound
          slice={outbound}
          passengerCount={passengerCount}
          bookingLocators={bookingLocators}
        />
      )}
      {!isEmpty(returning) && (
        <>
          <FlightCardRow
            locale={locale}
            slice={returning}
            passengerCount={passengerCount}
            bookingLocators={bookingLocators}
          />
        </>
      )}
    </Flex>
  );
};

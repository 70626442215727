import { Loading } from '@hc-claims/ui';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';

import { router } from './router';
import { QUERY_CLIENT_CONFIG } from './constants';

// Create a client
const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);

export const App = () => {
  return (
    <Suspense fallback={<Loading isOpen />}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Suspense>
  );
};

import {
  FAQsUrl,
  generateContactUsUrl,
  getCXLang,
  PartnerId,
} from '@hc-claims/utilities';
import { useCallback, useMemo } from 'react';
import { Box, Flex, Button } from 'theme-ui';
import { usePartnerContext } from '@hc-claims/ui';
import { usePartnerTranslations } from '../../hooks';
import { useStore } from '../../store';

const generateFAQUrl = (partnerKey: PartnerId, lang: string) => {
  const url = partnerKey
    ? FAQsUrl[partnerKey]
    : FAQsUrl[PartnerId.TRAVIX_BUDGET_AIR];
  if (lang === 'en') return url.replace('{{lang}}', '');
  return url.replace('{{lang}}', `lang/${lang}`);
};

export const ContactUs = () => {
  const { t } = usePartnerTranslations();
  const { partnerId } = usePartnerContext();
  const locale = useStore((state) => state.locale);

  const cxLang = useMemo(() => getCXLang(locale), [locale]);

  const faqUrl = useMemo(
    () => generateFAQUrl(partnerId, cxLang),
    [cxLang, partnerId],
  );

  const contactUsUrl = useMemo(
    () => generateContactUsUrl(partnerId, locale),
    [partnerId, locale],
  );

  const openFAQ = useCallback(() => {
    window.open(faqUrl, '_blank');
  }, [faqUrl]);

  const openContactUs = useCallback(() => {
    window.open(contactUsUrl, '_blank');
  }, [contactUsUrl]);

  return (
    <Flex
      sx={{
        position: 'absolute',
        mt: 30,
        bottom: 20,
        padding: '20px 24px',
        width: '100%',
        gap: 4,
        variant: 'styles.div.contactUs',
        flexDirection: ['column', 'row'],
      }}
    >
      <Button
        sx={{
          background: 'white',
          color: 'primary',
          border: '1px solid',
          borderColor: 'border',
          width: '100%',
          variant: 'buttons.contactUsPage.faq',
        }}
        data-testid="faq-button"
        onClick={openFAQ}
      >
        {t?.('faq')}
      </Button>
      <Button
        sx={{ width: '100%', variant: 'buttons.contactUsPage.contactUs' }}
        data-testid="contact-us-button"
        onClick={openContactUs}
      >
        <Flex
          sx={{
            variant: 'styles.div.footerButton',
            justifyContent: 'center',
          }}
        >
          {t?.('contact_us')}
          <Box sx={{ variant: 'styles.div.footerButtonContinueIcon' }} />
        </Flex>
      </Button>
    </Flex>
  );
};

import { getQueryParams } from '@hc-claims/utilities';
import { useStore } from '../store';

const SKIP_PARTNER_CALLBACK_PARAM = 'skipPartnerCallback';

export function setSkipContractValidation() {
  if (import.meta.env.MODE === 'production') {
    // Never skip contract validation in production
    return;
  }
  const { skipPartnerCallback } = getQueryParams(SKIP_PARTNER_CALLBACK_PARAM);
  const shouldSkip = skipPartnerCallback === 'true';
  const { setSkipPartnerCallback } = useStore.getState();
  setSkipPartnerCallback(shouldSkip);
}

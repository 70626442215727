import { getQueryParams } from '@hc-claims/utilities';

import { useStore } from '../store';

export const LOCALE_PARAM = 'locale';

export function localeFromQueryParameter() {
  const { locale } = getQueryParams('locale');
  const { locale: storedLocale, setLocale } = useStore.getState();

  const resolvedLocale = locale || storedLocale;

  if (resolvedLocale && resolvedLocale !== storedLocale) {
    setLocale(resolvedLocale);
  }

  return resolvedLocale;
}

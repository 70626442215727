import { merge, Theme } from 'theme-ui';

import agodaLogo from '../../assets/agodaLogo.svg';
import htsLogo from '../../assets/htsLogo.svg';
import { theme as defaultTheme } from './default';

const PRIMARY_COLOR = '#3170E7';
const BACKGROUND_COLOR = '#E3EDFF';

export const theme: Theme = merge(defaultTheme, {
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
    heading: 'system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
  },
  colors: {
    primary: PRIMARY_COLOR,
    accent: PRIMARY_COLOR,
    background: BACKGROUND_COLOR,
  },
  text: {
    header: {
      textAlign: 'center',
      pl: 0,
      fontWeight: '600',
    },
  },
  cards: {
    footer: {
      primary: {
        backgroundColor: 'transparent',
      },
      ghost: {
        backgroundColor: 'white',
        padding: 4,
      },
    },
  },
  styles: {
    div: {
      headerLogosContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 32px',
        pb: 3,
        backgroundColor: 'white',
      },
      contactUs: {
        position: ['relative', 'absolute'],
        backgroundColor: 'white',
      },
    },
    logos: {
      leftLogo: {
        backgroundImage: `url(${agodaLogo})`,
        backgroundRepeat: 'no-repeat',
        width: '48px',
        height: '24px',
      },
      rightLogo: {
        backgroundImage: `url(${htsLogo})`,
        backgroundRepeat: 'no-repeat',
        width: '92px', // htsLogo is 92px, so using that as width
        height: '24px',
      },
    },
  },
});

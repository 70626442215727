import { Box } from 'theme-ui';

type Props = {
  status: string;
  t: (s: string) => string;
};

export const StatusBadge = ({ status, t }: Props) => {
  if (!status) return null;

  const getStatus = () => {
    switch (status) {
      case 'active':
      case 'expired': {
        return status;
      }
      default: {
        return 'unavailable';
      }
    }
  };
  return (
    <Box
      data-testid="status-badge"
      sx={{ variant: `styles.div.statusBadge.${getStatus()}` }}
    >
      {t?.(getStatus())}
    </Box>
  );
};

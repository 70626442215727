import { base } from '@theme-ui/presets';
import { merge, Theme } from 'theme-ui';

import checkIcon from '../../assets/checkmark.svg';
import errorIcon from '../../assets/error.svg';
import dropdownIcon from '../../assets/dropdown.svg';

const BACKGROUND_COLOR = '#EFEFEF';
const BORDER_COLOR = '#CCCCCC';
const HEADING_COLOR = '#000000';
const TEXT_COLOR = '#000000';
const SUCCESS_COLOR = '#4C9C35';
const WARNING_COLOR = '#F28522';
const ERROR_COLOR = '#DE3E52';
const INFO_COLOR = '#00B1BC';
const CHARCOAL_COLOR = '#36454F';

const SPACING = [0, 2, 4, 8, 16, 24, 32, 64];

const HOVER_GRADIENT_STYLE = {
  backgroundImage: 'linear-gradient(rgb(0 0 0/20%) 0 0)',
};

export const theme: Theme = merge(base, {
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
  },
  colors: {
    muted: BACKGROUND_COLOR,
    background: BACKGROUND_COLOR,
    border: BORDER_COLOR,
    text: TEXT_COLOR,
    heading: HEADING_COLOR,
    success: SUCCESS_COLOR,
    warning: WARNING_COLOR,
    error: ERROR_COLOR,
    info: INFO_COLOR,
    backButton: TEXT_COLOR,
  },
  space: SPACING,
  text: {
    default: {
      color: TEXT_COLOR,
      fontWeight: 300,
    },
    heading: {
      color: HEADING_COLOR,
      paymentMethodsPage: {
        mb: '16px',
      },
    },
    highlight: {
      color: 'primary',
    },
    error: {
      color: ERROR_COLOR,
      fontSize: '0.75em',
    },
    bold: {
      fontWeight: '1000',
    },
    italic: {
      color: CHARCOAL_COLOR,
      fontStyle: 'italic',
    },
  },
  sizes: {
    container: 1024,
  },
  buttons: {
    primary: {
      height: 60,
      padding: SPACING[3],
      width: 'auto',
      cursor: 'pointer',
      backgroundColor: 'accent',
      borderRadius: SPACING[0],
      justifyContent: 'center',
      paddingLeft: '100px',
      paddingRight: '100px',

      '@media (hover: hover)': {
        '&:hover:enabled': HOVER_GRADIENT_STYLE,
      },
    },
    secondary: {
      backgroundColor: 'muted',
      color: 'text',
      border: '1px solid',
      borderColor: BORDER_COLOR,

      '@media (hover: hover)': {
        '&:hover:enabled': HOVER_GRADIENT_STYLE,
      },
    },
    icon: {
      color: 'primary',
      cursor: 'pointer',
    },
  },
  forms: {
    input: {
      color: 'text',
      padding: SPACING[3],
      border: '1px solid',
      borderColor: BORDER_COLOR,
      borderRadius: SPACING[0],
    },
    select: {
      hyperwalletDropdownIcon: {
        appearance: 'none',
        backgroundPositionX: '100%',
        backgroundPositionY: 'center',
      },
      dropdownIcon: {
        position: 'absolute',
        right: '4px',
        backgroundPositionY: 'center',
        backgroundImage: `url(${dropdownIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundOrigin: 'content-box',
        width: '24px',
        height: '24px',
        backgroundColor: 'white',
        alignSelf: 'center',
      },
      height: 42,
      padding: SPACING[3],
      backgroundColor: 'transparent',
      border: '1px solid',
      borderColor: BORDER_COLOR,
      appearance: 'none',
      borderRadius: SPACING[0],
      divider: {
        display: 'none',
      },
    },
    label: {
      fontSize: '0.8em',
      color: TEXT_COLOR,
    },
  },
  layout: {
    container: {
      pt: [0, 4],
    },
  },
  cards: {
    primary: {
      padding: SPACING[6],
      background: 'white',
      boxShadow: 'none',
      marginBottom: 3,
    },
    outlined: {
      padding: SPACING[6],
      background: 'white',
      border: '1px solid',
      borderColor: BACKGROUND_COLOR,
    },
    ghost: {
      marginBottom: 3,
      padding: SPACING[6],
      background: 'transparent',
    },
    footer: {
      primary: {
        padding: SPACING[6],
        background: 'white',
        boxShadow: 'none',
        display: 'flex',
        paddingLeft: '50%',
        justifyContent: 'end',
      },
      ghost: {
        marginBottom: 0,
        padding: SPACING[6],
        background: 'transparent',
        boxShadow: 'none',
      },
    },
    registerPage: {
      cashRefundCard: {
        display: 'none',
      },
    },
  },
  styles: {
    // this is here for hyperwallet scss
    hoverGradient: HOVER_GRADIENT_STYLE,
    hr: {
      display: 'none',
    },
    root: {
      fieldset: {
        padding: 0,
        border: 0,
        margin: 0,
      },
      legend: {
        fontSize: 1,
        float: 'left',
        width: '100%',
        color: HEADING_COLOR,
        marginBottom: SPACING[5],
      },
      h2: {
        variant: 'text.heading',
        fontSize: 3,
      },
      h3: {
        variant: 'text.heading',
        fontSize: 2,
      },
    },
    div: {
      errorTemplate: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20,
        marginTop: 20,
        width: '100%',
        position: 'absolute',
        bottom: '20px',
        padding: '0 10px',
        gap: 16,
      },
      statusBadge: {
        active: {
          display: 'none',
        },
        expired: {
          display: 'none',
        },
        unavailable: {
          display: 'none',
        },
      },
      registerPage: {
        cashRefundCard: {
          display: 'none',
        },
      },
      headerLogosContainer: {
        display: 'none',
      },
      footerSpacerMobile: {
        clear: 'both',
        height: '120px',
      },
      footerContainerMobile: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
      },
    },
    icons: {
      color: 'primary',
      checkIcon: {
        backgroundImage: `url(${checkIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundOrigin: 'content-box',
      },
      errorIcon: {
        backgroundImage: `url(${errorIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundOrigin: 'content-box',
      },
    },
  },

  radii: {
    radius: SPACING[0],
  },
});
